import React from 'react'
import './dashboard.scss'
import Admin from '../../Admin/Admin'
import Featured from '../featured/Featured'
import WelcomeFeatured from '../featured/WelcomeFeatured'
import Chart from '../chart/Chart'
import Table from '../table/Table'

const Dashboard = () => {
  return (
                    <Admin>

    <div>
                    <div className="chartsContainer">
          <Featured/>
          {/* <Chart title='Last 6 Months(Revenue)' aspect={2/1} /> */}
          <WelcomeFeatured/>

        </div>
        <div className="listContainer">
          <div className="listTitle">Latest Transactions</div>
          <Table/>
        </div>
    </div>
                    </Admin>
  )
}

export default Dashboard