const Button = ({text, style, btnClass, btnClick , loading}) => {
  return (
    <div>
     <button type='submit' className={btnClass} style={style} onClick={btnClick}>
      {text}
      </button>

    </div>
  )
}

export default Button