import React from 'react'
import Footer from '../Footer/index'
import Navbar from '../Navbar/index'

const AppLayout = ({children}) => {
  return (
    <div className='layoutDiv'>
     <div className="navbarDiv">
      <Navbar/>
     </div>
     {children}
     <div className="footerDiv">
     <Footer/>
     </div>
    </div>
  )
}

export default AppLayout;