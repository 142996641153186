import React from 'react'
import './Home.css';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import Button from '../../../../shared/Button';
import arrow from '../../../../asset/images/arrow.svg'

const Home = () => {
  const lP ={
    width:'150px',
  height:'40px',
  background:'green',
  color:'#000',
  fontSize:'1.2rem',
  borderRadius:'20px',
  textAlign:'center',
  border:'none',
  cursor:'pointer',
  transition:'1000s',
  fontWeight:'700'
  }

  return (
    <div className='landingDiv'>
     <section className='section1'>
    {/* Carousel Component */}
    <Carousel className='carousel' autoPlay='true' infiniteLoop='true' interval='3000' showThumbs={false}>
            <div className='carouseldiv11'>
              <p className='section1ContentHead'>MBBPIL- a platform to actualize your business dreams...</p>
              <Link to='/getstarted'>
              <div>
                <Button text={"Get Started"} style={lP} icon={arrow} />
              </div>
              </Link>
            </div>

            <div className='carouseldiv41'>
              <p className='section1ContentHead'>... Live a healthy lifestyle ...</p>
              <Link to='/getstarted'>
              <div>
                <Button text={"Get Started"} style={lP} icon={arrow} />
              </div>
              </Link>
            </div>
            <div className='carouseldiv61'>
              <p className='section1ContentHead'>... and secure the future financially</p>
              <div>

              <Link to='/getstarted'>
              <div>
                <Button text={"Get Started"} style={lP} icon={arrow} />
              </div>
              </Link>
              </div>

            </div>
          </Carousel>


     </section>

     <section className='section2'>
      <div className="sectionB">

      <div className='sectionBdiv1'>
        <h3 className='sectionBhead'>
          Are you a……
           </h3>

           <ul>
            <li className='sectionBListing'>👉Business Person?</li>
            <li className='sectionBListing'>👉Professional?</li>
            <li className='sectionBListing'>👉Civil Servant?</li>
            <li className='sectionBListing'>👉Student?</li>
            <li className='sectionBListing'>👉Skilled or Unskilled Worker?</li>
            {/* <li className='sectionBListing'>👉</li> */}
           </ul>
      </div>

           <div className='sectionBB'>
           <h3 className='sectionBhead'>
            Do you want….
           </h3>
           <ul>
           <li className='sectionBListing'>👉A Healthy Lifestyle?</li>
           <li className='sectionBListing'>👉Adequate financial security?</li>
           <li className='sectionBListing'>👉Self actualization?</li>

           </ul>

           </div>

      

      </div>
      <p className='sectionBP' >
Then you need to join Millionaires and Billionaires Business Partners International Limited
</p>

 <div className='sectionBdiv2'>
        <h2 className='sectionBh2'>
      MBBPIL Offers....
        </h2>
        <ul>
          <li className='sectionBListing'>1️⃣ Business Training and Management</li>
          <li className='sectionBListing'>2️⃣ Health and Lifestyle Training</li>
          <li className='sectionBListing'>3️⃣ Industrial and Agro-Economic workshops/Seminars</li>
          <li className='sectionBListing'>4️⃣ Business Startup and Growth Capital in Partnership with MBBPIL</li>
          <li className='sectionBListing'>5️⃣ Social Services</li>
        </ul>
      </div>
<p className='sectionBPara'>

Two (2) platforms have been designed to achieve these objectives using MULTI-LEVEL COOPERATIVE TECHNIQUES. <br />
These are the <Link to='/main' className='sectionBLink'>MBBPIL Membership Platform</Link>  and the <Link to='/Welcomeback' className='sectionBLink'>MBBPIL Welcomeback Platform</Link> 
</p>

      <Link to='/getstarted'>
              <div className='getStart'>
                <Button text={"Get Started"} style={lP} icon={arrow} />
              </div>
              </Link>
              
     </section>
    </div>
  )
}

export default Home