import axios from "axios";

const baseUrl = "https://mbbpilpartners.cyclic.app"

 export const GetTransactionDetails = async(callback) => {
try {
 const res = await axios.get(`${baseUrl}/admin/adminTransactionAmount`, {
  headers: {
   "content-type": "application/json" ,
   Accept: "application/json"
    }
 })
 callback(res)
} catch (error) {
 callback(error)
}
 }

export const UserCount= async(callback) => {
 try {
  const res = await axios.get(`${baseUrl}/admin/countUsers`, {
   headers: {
    "content-type": "application/json" ,
    Accept: "application/json"
     }
  })
  callback(res)
 } catch (error) {
  callback(error)
 }
}

export const AllMAIN= async(callback) => {
   try {
    const res = await axios.get(`${baseUrl}/admin/viewMainUser`, {
     headers: {
      "content-type": "application/json" ,
      Accept: "application/json"
       }
    })
    callback(res)
   } catch (error) {
    callback(error)
   }
  }