import React, {useState} from 'react'
import UserLayoutw from '../../../../layout/UserLayout/WelcomebackUserLayout';
import '../../../shared/CSS/ReferralCode.css'
import { useSelector } from 'react-redux'
// import ShareIcon from '../../../../assets/images/shareIcon.svg'
// import LikeButton from '../Button/LikeButton/LikeButton'
import {EmailShareButton, FacebookShareButton,  TwitterShareButton, WhatsappShareButton, EmailIcon, FacebookIcon, TwitterIcon, WhatsappIcon,} from "react-share";
import { FaTimesCircle } from 'react-icons/fa';

const ReferralCodew = () => {

  const user =useSelector(state=>state.allUser.user)

  //Usestate for Share button
  const [shareToggle, setShareToggle] = useState(false)

  // const handleShareToggle = () => {
  //   return setShareToggle(prevToggle =>!prevToggle)
  // };
  const timesButtonStyle = { fontSize: "2rem", cursor: 'pointer' };

  const sharedUrl = 'https://mbbpilpartners.com/';


  return (
    <UserLayoutw>
    <div className='referralcodeContainer'>
     <h2 className='referralCodehead'>
      Here's your referral code

     </h2>

     <p className='referralcodeContent'>
      Goodday {user.fullName}, below is your referral code.
      you can either share or copy it 
     </p>

     <p className='code'>{user.referralCode}</p>
     <div className='sharelikediv'>
                  {/* <div onClick={handleShareToggle}>
                    {shareToggle ? <FaTimesCircle style={timesButtonStyle}/> :  <img src={ShareIcon} className='shareimg' alt="share-icon" />}
                  </div>
                  <div className='like-div'>
                    <LikeButton style={timesButtonStyle} />
                  </div> */}
                  <div className={shareToggle ? 'shareactive' : 'shareInactive' }>
                    <div className="buttonDiv">

                      <div className='shareIcon'>
                        <FacebookShareButton url='https://facebook.com?mmbpil.vercel.app/{user.referralCode}'>
                          <FacebookIcon size={25} round={true} />
                        </FacebookShareButton>
                      </div>
                      <div className='shareIcon'>
                        <EmailShareButton url='https://gmail.com?mmbpil.vercel.app/{user.referralCode}'>
                          <EmailIcon size={25} round={true} />
                        </EmailShareButton>
                      </div>
                      <div className='shareIcon'>
                        <TwitterShareButton url='https://twitter.com?mmbpil.vercel.app/{user.referralCode}'>
                          <TwitterIcon size={25} round={true} />
                        </TwitterShareButton>
                      </div>
                      <div className='shareIcon'>
                        <WhatsappShareButton url='https://whatsapp.com?mmbpil.vercel.app/{user.referralCode}'>
                          <WhatsappIcon size={25} round={true} />
                        </WhatsappShareButton>
                      </div>
                    </div>
            
                    </div>
              </div>
    </div>
    </UserLayoutw>
  )
}

export default ReferralCodew