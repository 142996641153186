import React from 'react'
import '../../../shared/CSS/UpdatePassword.css'
import { useFormik } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import Button from '../../../../shared/Button';
import { useNavigate } from 'react-router-dom';
import UserLayout from '../../../../layout/UserLayout/MainUserLayout'
import swal from 'sweetalert';

const UpdatePassword = () => {

    const confirmStyle= {
          width:'170px',
          height:'40px',
          background:'green',
          color:'#000',
          fontSize:'1.2rem',
          borderRadius:'8px',
          textAlign:'center',
          border:'none',
          cursor:'pointer',
          transition:'1000s'
         }

         const navigate =useNavigate()

  // Formik logic statement
  const formik = useFormik({
    initialValues: {  
      email:"",
      password: "", 
    }, 

    // Formik Submit
    onSubmit: (values) => {
      axios
        .post("https://mbbpils-xi.vercel.app/auth/resetpassword", values)
        .then(response => {
          // console.log(response);
          swal(`${response.data.message}`);
            navigate('/mainresetpasswordconfirmation')
        })
        .catch(error => {
            // console.log(error)
            swal("Failed", `${error.response.data.message}`);
        });
    },

    // Validation of form values
    validationSchema: Yup.object({
      email: Yup.string()
       .required("Enter email")
      ,
      password: Yup.string(),

      // confirmPassword: Yup.string()
      //   .oneOf([Yup.ref('password'), null], 'Confirm your password')
      //   .test('passwords-match', 'Passwords must match', function(value){
      //       return this.parent.password === value
      //   }),
    })
  });

  return (
    <UserLayout>

    <div className='newPasswordContainer'>
      <form onSubmit={formik.handleSubmit}>
        <div className="newPassDiv">

        <div className="input-wrap">
            <label 
              className={formik.touched.email && formik.errors.email ? "small_tag" : "input_label"}
              htmlFor="password">
              {formik.errors.email ? formik.errors.email : "Enter email address"}
              </label>
              <input 
                         className="input_type" 
                         type="email" 
                         value={formik.values.email} 
                         onChange={formik.handleChange} 
                         id="email" 
                         name="email" 
                         placeholder="email" 
                         onBlur={formik.handleBlur}
                       />
              <small className="small_tag"></small>
              </div>

          <div className="input-wrap">
            <label 
              className={formik.touched.password && formik.errors.password ? "small_tag" : "input_label"}
              htmlFor="password">
              {formik.errors.password ? formik.errors.password : "Enter New Password"}
              </label>
              <input 
                         className="input_type" 
                         type="password" 
                         value={formik.values.password} 
                         onChange={formik.handleChange} 
                         id="password" 
                         name="password" 
                         placeholder="Password" 
                         onBlur={formik.handleBlur}
                       />
              <small className="small_tag"></small>
              </div>
              
              <div className="input-wrap">
        {/* <label 
          className={formik.touched.confirmPassword && formik.errors.confirmPassword ? "small_tag" : "input_label"} 
          htmlFor="confirmPassword">
          {formik.errors.confirmPassword ? formik.errors.confirmPassword :  "Confirm Password"}
        </label>
        <input 
          className="input_type" 
          type="password" 
          value={formik.values.confirmPassword} 
          onChange={formik.handleChange} 
          id="confirmPassword" 
          name="confirmPassword" 
          placeholder="Confirm Password" 
          onBlur={formik.handleBlur}
        /> */}
        {/* <small className="small_tag"></small> */}
      </div>
    </div>
    <Button type="submit" style={confirmStyle} text="Confirm" />
  </form>
</div>
          </UserLayout>
)
}

export default UpdatePassword;              