import React from 'react'
import '../../../../shared/CSS/UserSideBar.css'
import { Link } from 'react-router-dom'
import { FaWallet } from "react-icons/fa";
// import { FaUserNurse } from "react-icons/fa";


const UserSideBar = () => {
  const signOut = () =>{
    localStorage.clear()
  }
  return (
    <div className='userContainer'>
     <ul className='listContainer'>
      <li className='userList'>
       <Link className='sidebar' to='/maindashboard'>
       Dashboard</Link></li>
       <hr />
      <li className='userList'>
       {/* <img src={FaWallet} alt="" /> */}
       <Link className='sidebar' to='/mainbankdetails'>Bank Details</Link>
      </li>
      <hr />

      <li className='userList'><Link className='sidebar' to='/mainpaystack'>Make Payment</Link></li>
      <hr />

      <li className='userList'><Link className='sidebar' to='/mainupdatepassword'>Update Password</Link></li>
      <hr />

      <li className='userList'><Link className='sidebar' to='/mainreferralcode'>Referral code</Link></li>
      <hr />

      <li className='userList'><Link className='sidebar' to='/mainSignin' onClick={()=>signOut()}>Sign Out</Link></li>
      <hr />

     </ul>

    </div>
  )
}

export default UserSideBar