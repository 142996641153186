import React, { useState } from "react";
import '../../../shared/CSS/Makepayment.css'
import axios from "axios";
// import UserLayoutw from "../../../../layout/UserLayout/WelcomebackUserLayout";
// import { useNavigate } from "react-router-dom";

const Paystackw = () => {
  const [email, setEmail] = useState('');
  const [paymentUrl, setPaymentUrl] = useState('');
  // const [data, setData] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://mbbpil-welback.vercel.app/auth/welcomePaystack', {
        email,
      })
      ;
      // console.log(response)
      setPaymentUrl(response.data);
    } catch (error) {
      // console.log(error);
    }
  };


  return (
    // <UserLayoutw>

    <div className="app">
      <div className="container">
        <h4>ENTER YOUR EMAIL ADDRESS TO ENABLE YOU MAKE PAYMENT ON THE WELCOME BACK PLATFORM</h4>
        <div className="checkout-form">
        <form onSubmit={handleSubmit}>
          <div>

        <label className="label">
          Email:
          </label>
          </div>
          <input 
          className="inputElement"
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} />
      
      <button type="submit" className="paysBtn">Proceed</button>

          </form>
      
      {paymentUrl && (
        <div>
          <a href={paymentUrl}>
            <button>PAY NOW</button>
          </a>
          {/* <p>Payment URL: {paymentUrl}</p> */}
          {/* Redirect the user to paymentUrl to complete the payment */}

          {/* <p>{dataa.data}, {dataa}</p> */}
        </div>
      )}



    </div>




        </div>
      </div>
  
  );
};

export default Paystackw;
