import React , {useState}from 'react'
import Button from '../../../../shared/Button'
import UserLayout from '../../../../layout/UserLayout/WelcomebackUserLayout'
import '../../../shared/CSS/BankDetails.css'
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const accountNumberReg = /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const accountSchema =Yup.object().shape({
  email:Yup.string()
  .required('Please enter your email'),

  accountName: Yup.string()
  .required('Please enter your account name'),

  accountNumber: Yup. string()
  .matches(accountNumberReg, 'Please enter a valid account number')
  .required('Please enter your account number'),

    type: Yup.string()
    .required('Please select the account type'),

    bankName: Yup.string()
    .required('Please select your bank')
})

const BankDetails = () => {
  /* Server State Handling */
  const [serverState, setServerState] = useState();
  const handleServerRes = (ok, msg) => {
    setServerState({ok, msg});
  };

  // API consumption
  const handleOnSubmit = (values, actions) => {
    axios.post("https://mbbpils-xi.vercel.app/auth/register", values)
      .then(res => {
        // console.log(res)
        swal("Successful", `${res.data.message}`)
                navigate('/mainupdatesuccess')
                actions.setSubmitting(false);
                actions.resetForm();
                // handleServerRes(true, 'Bank details submitted successfully');
          })
          .catch(error => {
            swal("Failed", `${error.res?.data.message}`)
            // console.log(error)
              actions.setSubmitting(false);
              // handleServerRes(false, 'An error occured, please try again later');
          });
  };


  const navigate =useNavigate()

  const BankDetailButton = {
    width:'150px',
    height:'40px',
    background:'green',
    color:'#fff',
    fontSize:'1.2rem',
    borderRadius:'8px',
    textAlign:'center',
    border:'none',
    cursor:'pointer',
    transition: '1000ms'
  }
  return (
    <UserLayout>
      <div>
        <h2 className='bankdetailshead'> Kindly Provide your Bank Details</h2>
        <p className='note'>Note: Your Informations are safe on our website as we will not given to third party </p>

        {serverState && (
          <p className={!serverState.ok ? "errorMsg" : "successMsg"}>
            {serverState.msg}
          </p>
        )}

        <Formik
          initialValues={{
            email: '',
            type: '',
            accountNumber: '',
            accountName: ''
          }}
          validationSchema={accountSchema}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="bankDetailsForm">
              <Field 
              type="email" 
              name="email" 
              id="email" 
              placeholder="enter your email" 
              className="inputFiller" />
              
              <div className='acctType'>

              <Field
                component="select"
                name="type"
                id="type"
                className="selectContainer"
                placeholder="Select Account Type"
              >
                <option value="">Select Account Type</option>
                <option value="savings">Savings</option>
                <option value="current">Current</option>
              </Field>
              </div>

            <div className='acN'>
              <Field 
              type="text" 
              name="accountNumber" 
              id="accountNumber" 
              placeholder="Fill in your account number"
               className="inputFiller" />
          </div>
          <div className='accN'>
              <Field 
              type="text" 
              name="accountName" 
              id="accountName" 
              placeholder="Name on Account" 
              className="inputFiller" />
              </div>
                <div className="bankSelect">
                <div>
                <label htmlFor="bankName">Your Bank</label>
                </div>

                {/*ACCOUNT TYPE CONTAINER */}
                <div className='acctType'>
                <Field
                  component="select"
                  name="bankName"
                  id="bankName"
                  className="selectContainer"
                  placeholder="Select Bank"
                  >
                <option value="">Select Bank</option>
                <option value="gtBank">Guaranty Trust Bank</option>
                <option value="firstBank">First Bank</option>
                <option value="fidelity">Fidelity</option>
                <option value="ecoBank">Eco Bank</option>
                <option value="uba">United Bank For Africa</option>
                <option value="sterling">Sterling</option>
                <option value="fcmb">FCMB</option>
                <option value="fcmb">Zenith</option>
                <option value="fcmb">Providus</option>
                <option value="fcmb">Wema Bank</option>
                <option value="fcmb">Access Bank Plc</option>
                <option value="fcmb">Union Bank</option>
                <option value="fcmb">Citibank Nigeria Limited</option>
                <option value="fcmb">Heritage Bank Plc</option>
                <option value="fcmb">Keystone Bank Plc</option>
                <option value="fcmb">Optimus Bank Limited</option>
                <option value="fcmb">Polaris Bank Limited</option>
                <option value="fcmb">Stanbic IBTC Bank Plc</option>
                <option value="fcmb">Standard Chartered</option>
                <option value="fcmb">Titan Trust Bank</option>
                <option value="fcmb">Unity Bank</option>
                <option value="fcmb">Globus Bank</option>
                <option value="fcmb">Parallex Bank Limited</option>
                <option value="fcmb">Premium Trust Bank</option>
                <option value="fcmb">SunTrust Bank Nigeria Limited</option>
                <option value="fcmb">Jaiz Bank Plc</option>
                <option value="fcmb">LOTUS Bank</option>
                <option value="fcmb">TAJBank Limited</option>

                </Field>
                </div>
                </div>
                <div className="bankdetailsButton">
                <Button style={BankDetailButton} disabled={isSubmitting} text={'Submit'}/>
                </div>
                </Form>
                )}
                </Formik>
                </div>
                </UserLayout>
                );
                }

                export default BankDetails;