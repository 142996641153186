import React from 'react'
import '../../../shared/PlatformHome/PlatformHome'
import { Link } from 'react-router-dom';
import { mainPageData } from '../../../constants/data';
import PlatformHome from '../../../shared/PlatformHome/PlatformHome';

const MainHomepage = () => {
  return (
   <section className='stage'>

<h1 className='stageHead'>MEMBERSHIP (MAIN) PLATFORM</h1>



    <div>
    <div className='stageFlex'>
    <table className='stageTable'>
      <tr >
      <th></th>
      <th className='stageLists'>Membership stages</th>
      </tr>
      <tr>
        <td className='stageListq'>Membership of MBBPIL is open to all individuals who have set visions or goals to achieve.</td>
        <td className='stageLists'>Stage 0 – Welcome </td>

      </tr>
      <tr>
        <td className='stageListq'>You shall become a Member when you:</td>
        <td className='stageLists'>Stage 1 – Associate</td>

      </tr>
      <tr>
        <td className='stageListq'>👉	Fill/complete the membership registration form</td>
        <td className='stageLists'>Stage 2 – Honourable</td>

      </tr>
      <tr>
        <td className='stageListq'>👉	Pay a one-time non-refundable registration fee of Fifty Thousand Naira (N50,000) only</td>
        <td className='stageLists'>Stage 3 – Chairman</td>

      </tr>
      <tr>
        <td className='stageListq'>👉	You shall also ensure that you introduce and register 2 goals achievers (2 new members) under you to MBBPIL. This will move you to the next stage (Associate stage)</td>
        <td className='stageLists'>Stage 4 – Minister</td>

      </tr>
      <tr>
        <td className='stageListq'>👉	The new members you introduced shall also do what you did (introduce and register 2 new members each under them) to move up the matrix as shown:</td>
        <td className='stageLists'>Stage 5 – Executive</td>

      </tr>
      <tr>
        <td className='stageListq'></td>
        <td className='stageLists'>Stage 6 – Eldorado</td>

      </tr>
    </table>
   </div>

   {/* Fetched data from the constant folder in src */}

    {mainPageData.map((item) => (
      <PlatformHome 
              key={item.id} 
              stage={item.stage} 
              stageHead={item.stageHead} 
              activitiesPara={item.activitiesPara} 
              stageList1={item.stageList1} 
              stageList2={item.stageList2} 
              stageList3={item.stageList3} 
              stageList4={item.stageList4}
              benefitList1={item.benefitList1} 
              benefitList2={item.benefitList2} 
              benefitList4={item.benefitList4} 
              benefitList3={item.benefitList3} 
              benefitList5={item.benefitList5}
              contentparaList1={item.contentparaList1}
              contentparaList2={item.contentparaList2}
              contentparaList3={item.contentparaList3}
              contentparaList4={item.contentparaList4}
              contentparaList5={item.contentparaList5}
              contentparaList6={item.contentparaList6}
              contentList1={item.contentList1}
              contentList2={item.contentList2}
              contentList3={item.contentList3}
              contentList4={item.contentList4}
              contentList5={item.contentList5}
              contentList6={item.contentList6}
              contentList7={item.contentList7}
              />
              ))}
              </div>

              <div className='stageBtn'>
           <Link to='/mainsignup'>
        <button className='registerMe'> 
            REGISTER NOW
        </button>
           </Link>

           <Link to='/mainsignin'>
        <button className='registerMe'> 
            Login
        </button>
           </Link>
          </div>

   </section>
  )
}

export default MainHomepage