import React from 'react'
import { Link } from 'react-router-dom'
import './Getstarted/Getstarted.css'

const Payment = () => {
  return (
    <div className='loginBtnDiv'>
    <div className='bttt'>
     <h1 className='loginTo'>
      CHOOSE PLATFORM FOR PAYMENT
     </h1>
     
      <div className='bttFlex'>

     <Link to='/mainpaystack'>
      <div className='welcomeb'>
       MAIN PLATFORM
       <p className='buttwords'>₦50,000</p>
      </div>
     </Link>

     <Link to='/welcomebackpaystack'>
      <div className='welcomebp'>
       WELCOME BACK PLATFORM
       <p className='buttwords'>₦5,000</p>
      </div>
     </Link>
      </div>


    </div>
    </div>
  )
}

export default Payment