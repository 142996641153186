import React from 'react'
import './Footer.css'
import Logo from '../../asset/images/logo.jpeg'
import { Link } from 'react-router-dom'
import { FaInstagram } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";

const Footer = () => {
  return (
    <div className='footerContainer'>
      <div className='footerBox'>
      <div className="div1">
        <Link to='/'>
        <img src={Logo} alt="Logo" className='footerLogo'/>
        </Link>
      <h2>MBBPIL</h2>
      </div>
      <div className="div2">
       <li><Link to='/About' className='div2Link'>ABOUT US</Link></li>
       <li><Link to='/main' className='div2Link'>MAIN PLATFORM</Link></li>
       <li><Link to='/welcomeback' className='div2Link'>WELCOME BACK PLATFORM</Link></li>
       <li><Link to='/getstarted' className='div2Link'>SIGN UP</Link></li>
       <li><Link to='/Contact' className='div2Link'>CONTACT US</Link></li>
       <li><Link to='/payment' className='div2Link'>MAKE PAYMENT</Link></li>
       <li><Link to='/terms' className='div2Link'>TERMS AND CONDITIONS</Link></li>
       <li><Link to='/faq' className='div2Link'>FAQs</Link></li>

      </div>
      <div className="div3">
      <h2 className='handle'>Social media handle</h2>
      <a href='./' target='_blanck'><FaInstagram className='faIcons'/></a>
        <a href='./' target='_blanck'><FaTwitterSquare className='faIcons'/></a>
        <a href='./' target='_blanck'><FaFacebookSquare className='faIcons'/></a>
      </div>

        </div>      

      <h4 className='copyright'>&copy; MBBPIL 2023</h4>

    </div>
  )
}

export default Footer