import React, { useState} from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import Logo from '../../asset/images/logo.jpeg'

const Navbar = () => {

  const navBarStyle = {
    width: '150px',
    height : "40px",
    backgroundColor: 'transparent',
    border: '1px solid white',
    color: '#fff',
    borderRadius: '10px',
    fontSize: '24px'

  }
   // Usestate for Hamburger display
   const [toggle, setToggle] = useState(false)

  const handleToggle = () => {
    return setToggle(prevToggle =>!prevToggle)
  }


  return (
    <div className='navbar'>
     <div className="navbarLogo">
      <Link to='/'>
      <img src={Logo} alt="Company's Logo"  className='navbarLogo'/>
      </Link>
     </div >

     {/* Menu List */}
     <div className={toggle ? 'active' : 'menuList' }>

      <ul className='menuList'>
       <li onClick={()=> setToggle(false)}><Link to='./' className='navbarList'>HOME</Link></li>
       <li onClick={()=> setToggle(false)}><Link to='/About' className='navbarList'>ABOUT US</Link></li>
       <li onClick={()=> setToggle(false)}><Link to='/main' className='navbarList'>MAIN PLATFORM</Link></li>
       <li onClick={()=> setToggle(false)}><Link to='/welcomeback' className='navbarList'>WELCOME BACK PLATFORM</Link></li>
       <li onClick={()=> setToggle(false)}><Link to='/getstarted' className='navbarList'>SIGN UP</Link></li>
       <li onClick={()=> setToggle(false)}><Link to='/Contact' className='navbarList'>CONTACT US</Link></li>
       <li onClick={()=> setToggle(false)}><Link to='/payment' className='navbarList'>MAKE PAYMENT</Link></li>
      </ul>
      

     </div>

      {/* Hamburger for mobile screen */}

      <div id="hamburger" onClick={handleToggle}>
              {toggle ? <div>&times;</div> :  <div>&#9776;</div>}
      </div>
    </div>
    
  )
}

export default Navbar;