import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import AppLayout from './layout/AppLayout';

// Shared views between the two platforms
import Home from './modules/shared/Views/Home/Index';
import About from './modules/shared/Views/About';
import Contact from './modules/shared/Views/Contact';
import Getstarted from './modules/shared/Views/Getstarted';
import Terms from './modules/shared/Views/Terms';
import Faq from './modules/shared/Views/FAQ/Faq'

//Main Dashboard sidebar components
import Success from './modules/main/views/SignupSuccess';
import MainHomepage from './modules/main/views/MainHomepage';
import Paymentprocess from './modules/shared/Views/Payment.jsx';
import BankDetails from './modules/main/views/UserDashboard/BankDetails';
import UserDashboard from './modules/main/views/UserDashboard/index';
import Payment from './modules/main/views/UserDashboard/MakePayment';
import UpdatePassword from './modules/main/views/UserDashboard/UpdatePassword';
import ReferralCode from './modules/main/views/UserDashboard/ReferralCode';
import ForgotPassword from './modules/main/views/ForgotPassword';
import ResetConfirmation from './modules/main/views/ResetConfirmation';
import ResetPassword from './modules/main/views/ResetPassword';
import UpdBankSucc from './modules/main/views/UpdBankSucc';
import Signin from './modules/main/views/Login'
import Signup from './modules/main/views/Signup'

// It ends here


// Welcome back imports
import WelcomebackHomepage from './modules/welcomeback/views/welcomeHomepage';
import Paymentprocessw from './modules/shared/Views/Payment.jsx';
import BankDetailsw from './modules/welcomeback/views/UserDashboard/BankDetails';
import UserDashboardw from './modules/welcomeback/views/UserDashboard/index';
import Paymentw from './modules/welcomeback/views/UserDashboard/MakePayment';
import UpdatePasswordw from './modules/welcomeback/views/UserDashboard/UpdatePassword';
import ReferralCodew from './modules/welcomeback/views/UserDashboard/ReferralCode';
import ForgotPasswordw from './modules/welcomeback/views/ForgotPassword';
import ResetConfirmationw from './modules/welcomeback/views/ResetConfirmation';
import ResetPasswordw from './modules/welcomeback/views/ResetPassword';
import UpdBankSuccw from './modules/welcomeback/views/UpdBankSucc';
import Signinw from './modules/welcomeback/views/Login'
import Signupw from './modules/welcomeback/views/Signup'
import Successwb from './modules/welcomeback/views/Successwb';
// It ends here

// Protectected routes for MAin user
import ProtectedRoutes from './Utilitities/User/main/ProtectedRoutes';
// it ends here

// Protectected routes for MAin user
import ProtectedRoutesw from './Utilitities/User/welcomeback/ProtectedRoutes';
// it ends here


// Admin Routes
import AdminHome from './modules/Admin/Components/login/Login'

// Routes Protected in Admin
import AdminDashboard from './modules/Admin/Components/Dashboard'
import User from './modules/Admin/Page/user/User'
import Userwb from './modules/Admin/Page/user/Userwb'
import MakeAdmin from './modules/Admin/Page/makeAdmin/makeAdmin';
import MainAdminCashout from './modules/Admin/Page/adminCashout/main';
import WelcomebackAdminCashout from './modules/Admin/Page/adminCashout/welcomeback';

// Admin ProtectedRoute
import ProtectedRoutesA from './Utilitities/Admin/ProtectedRoutes';


// Dark and Light mode context
import { DarkModeContext } from "./modules/Admin/context/darkModeContext";

// Preloader
import Preloader from "./layout/Preloader/Index";




function App() {
  const {darkMode} =useContext(DarkModeContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (document.readyState === "complete") {
      setLoading(true);
    } else {
      const handleLoad = () => setLoading(true);
      window.addEventListener("load", handleLoad);
      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  return (
    <div className={darkMode ? "whole dark" : " whole"}>
      <BrowserRouter>
      {loading? (

      
      <AppLayout>
        <Routes>

          {/* Shared views Route */}
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/getstarted' element={<Getstarted/>}/>
          <Route path='/terms' element={<Terms/>}/>
          <Route path='/payment' element={<Paymentprocess/>}/>
          <Route path='/faq' element={<Faq/>}/>

          {/* Main Routes Not Protected */}
          <Route path='/main' element={<MainHomepage/>}/>

          <Route path='/mainpaystack' element={<Payment/>}/>
         <Route path='/mainresetpassword' element={<ResetPassword/>}/>
         <Route path='/mainforgotpassword' element={<ForgotPassword/>}/>
         <Route path='/mainresetpasswordconfirmation' element={<ResetConfirmation/>}/>
         <Route path='/mainupdatesuccess'element={<UpdBankSucc/>}/>
         <Route path='/mainsignin' element={<Signin/>}/>
         <Route path='/mainsignup' element={<Signup/>}/>
         <Route path='mainsuccess' element={<Success/>}/>

         {/* Routes Welcomeback platform Not Protected */}

         <Route path='/welcomeback' element={<WelcomebackHomepage/>}/>
          <Route path='/welcomebackpaystack' element={<Paymentw/>}/>
         <Route path='/welcomebackforgotpassword' element={<ForgotPasswordw/>}/>
         <Route path='/welcomebackresetpassword' element={<ResetPasswordw/>}/>
         <Route path='/welcomebackresetpasswordconfirmation' element={<ResetConfirmationw/>}/>
         <Route path='/welcomebackupdatesuccess'element={<UpdBankSuccw/>}/>
         <Route path='/welcomebacksignin' element={<Signinw/>}/>
         <Route path='/welcomebacksignup' element={<Signupw/>}/>
         <Route path='/welcomebacksuccess' element={<Successwb/>}/>


        {/* PROTECTED ROUTES FOR MAIN USERS */}

        <Route path='/' element={<ProtectedRoutes/>}>
        <Route path='maindashboard' element={<UserDashboard/>}/>
         <Route path='mainbankdetails' element={<BankDetails/>}/>
         <Route path='mainreferralcode' element={<ReferralCode/>}/>
         <Route path='mainupdatepassword' element={<UpdatePassword/>}/>
         </Route>
         {/* ENDS HERE */}

         {/* PROTECTED ROUTES FOR WELCOMEBACK USERS */}
         <Route path='/' element={<ProtectedRoutesw/>}>
          <Route path='welcomebackdashboard' element={<UserDashboardw/>}/>
         <Route path='welcomebackbankdetails' element={<BankDetailsw/>}/>
         <Route path='welcomebackupdatepassword' element={<UpdatePasswordw/>}/>
         <Route path='welcomebackreferralcode' element={<ReferralCodew/>}/>
          
         </Route>
         {/* ENDS HERE */}


         {/* ADMIN ROUTES */}
         <Route path='/admin' element={<AdminHome/>}/>

         {/* ADMIN PROTECTED ROUTES */}
         <Route path='/' element={<ProtectedRoutesA/>}>
          <Route path='admindashboard' element={<AdminDashboard/>}/>
          <Route path='mainuser' element={<User/>}/>
          <Route path='welcomebackuser' element={<Userwb/>}/>
          <Route path='makeadmin' element={<MakeAdmin/>}/>
          <Route path='mainadmincashout' element={<MainAdminCashout/>}/>
          <Route path='welcomebackadmincashout' element={<WelcomebackAdminCashout/>}/>
         </Route>
        </Routes>

      </AppLayout>
      ) : (
        <Preloader/>
      )

    }
      </BrowserRouter>
    </div>
  );
}

export default App;
