import React from 'react'
import { Link } from 'react-router-dom'
import '../../shared/CSS/ResetConfirmation.css'

const ResetConfirmation = () => {
  return (
    <div>
     {/* confirmation page content */}
     <div className="confirmationContainer">
      <h2 className='confirmationh2'>Success ✔</h2>
      <p>You can now login with the new password</p>
      <Link to='/welcomebacksignin' className='resetLink'> Click Here To Login</Link>
     </div>
    
    </div>
  )
}

export default ResetConfirmation