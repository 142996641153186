import React, { useState } from 'react';
import axios from 'axios';
import './user.scss';
import Admin from '../../Admin/Admin';


const Userwb = () => {
  const [email, setEmail] = useState('');
  const [userInfo, setUserInfo] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

   // Make an HTTP POST request to the backend API
   axios.get(`https://mbbpil-admin.vercel.app/admin/toWelcomeBackUser/${email}`,`${email}`)
   .then(response => {
      //  console.log(response);
       const userData = response;
       setUserInfo(userData);
      //  console.log(userInfo)
       
     })
     .catch(error => {
       console.error('Error submitting email:', error);
     });
 };

 const handleInputChange = (event) => {
   setEmail(event.target.value);

  };

  return (
    <Admin>

        <div className='userBlock'>
      <h1>Welcomeback User Information</h1>
        <form onSubmit={handleSubmit}>
      <div className='userInputBlock'>
        <input className='userInput' type="email" value={email} onChange={handleInputChange} placeholder="Enter user email" />
        <button className='userBtn'>User Info</button>
      </div>
      </form>

      {userInfo && (
        <div className='informations'>
          <h2 className='userInformation'>User Information:</h2>
          <p className='endpoint'>Name: {userInfo.data.data.fullName}</p>
          <p className='endpoint'>Email: {userInfo.data.data.email}</p>
          <p className='endpoint'>Referral Code: {userInfo.data.data.referralCode}</p>
          <p className='endpoint'>Address: {userInfo.data.data.address}</p>
          <p className='endpoint'>Phone Number: {userInfo.data.data.phone}</p>
          <p className='endpoint'>Status: {userInfo.data.data.status}</p>
          <p className='endpoint'>Wallet: {userInfo.data.data.wallet.$numberDecimal}</p>
          <p className='endpoint'>Graduating Bonus: {userInfo.data.data.graduatingBonus.$numberDecimal}</p>
          <p className='endpoint'>Award: {userInfo.data.data.Award.$numberDecimal}</p>
          <p className='endpoint'>Laptop Award: {userInfo.data.data.laptopAward.$numberDecimal}</p>
          <p className='endpoint'>Matrix Bonus: {userInfo.data.data.matrixBonus.$numberDecimal}</p>
          <p className='endpoint'>Thankyou Bonus: {userInfo.data.data.thankYouBonus.$numberDecimal}</p>
          {/* <p className='endpoint'>Referral Count: {userInfo.data.referralCount.$numberDecimal}</p> */}
          <p className='endpoint'>Referral Node: {userInfo.data.data.referralNode.$numberDecimal}</p>
          <p className='endpoint'>Transaction Count: {userInfo.data.data.transaction_counts.$numberDecimal}</p>
        </div>
      )}
    </div>
    </Admin>
  );
};

export default Userwb;