import './table.scss'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from 'react';
import { AllMAIN } from '../../../../api/api';

const List = () => {

  const [allMainUser, setAllMainUser]= useState("")
  // const [allWelcomeUser, setAllWelcomeUser]= useState("")


  useEffect(() => {
    AllMAIN((res) => {
      console.log(res)
    if(res.status === 200) {
      setAllMainUser(res?.data?.mainUsers)
// setAllWelcomeUser(res?.data?.numberOfWelcomeBack[0]?.amount?.$numberDecimal)
    }
    })
  }, [])


  return (

    //  <TableContainer component={Paper} className='table'>
    //   <Table sx={{ minWidth: 650 }} aria-label="simple table">
    //     <TableHead>
    //       <TableRow>
    //       <TableCell className="tableCell">Reference</TableCell>
    //         <TableCell className="tableCell">Email</TableCell>
    //         <TableCell className="tableCell">Fullname</TableCell>
    //         <TableCell className="tableCell">Referral Code</TableCell>
    //         <TableCell className="tableCell">Amount</TableCell>
    //         <TableCell className="tableCell">Refferals</TableCell>
    //         <TableCell className="tableCell">Status</TableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       {allMainUser.map((mainUser) => (
    //         <TableRow key={mainUser.id}>
    //           <TableCell className="tableCell">{mainUser.id}</TableCell>
    //           <TableCell className="tableCell">
    //             {/* <div className="cellWrapper"> */}
    //               {/* <img src={row.img} alt="" className="image" /> */}
    //               {/* {mainUser.product} */}
    //             {/* </div> */}
    //           </TableCell>
    //           <TableCell className="tableCell">{mainUser.fullName}</TableCell>
    //           <TableCell className="tableCell">{mainUser.referralCode}</TableCell>
    //           <TableCell className="tableCell">{mainUser.wallet.$numberDecimal}</TableCell>
    //           <TableCell className="tableCell">{mainUser.referralNode.$numberDecimal}</TableCell>
    //           <TableCell className="tableCell">
    //             <span className={`status ${mainUser.status}`}>{mainUser.status}</span>
    //           </TableCell>
    //         </TableRow>
    //       ))}
    //     </TableBody>
    //   </Table>
    // </TableContainer>
    <div></div>
 
  )
}

export default List