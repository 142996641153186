import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './Carousel.css'


class Carousell extends Component {
    render() {
        return (
            <Carousel className='carousel' autoPlay='true' infiniteLoop='true' interval='6000' showThumbs={false}>
            <div className='carouseldiv1'>
              <p className='carouseltext'>MBBPIL – a platform to actualize your business dreams... </p>
              {/* <div>
                <Button text={"View Details"} btnclass={'carouselbutton'} icon={arrow} />
              </div> */}
            </div>
            <div className='carouseldiv2'>
                
              <p className='carouseltext'>……. live a healthy lifestyle ……..   </p>
              {/* <div>
                <Button text={"View Details"} btnclass={'carouselbutton'} icon={arrow} />
                
              </div> */}
            </div>
            <div className='carouseldiv3'>
              <p className='carouseltext'>……. and secure the future financially.</p>
              {/* <div>
                  <Button text={"View Details"} btnclass={'carouselbutton'} icon={arrow} />
              </div> */}
            </div>
          </Carousel>
        );
    }
};

export default Carousell;