// import { ActionTypes } from "../Constant/ActionTypes";

// const initialState = {
//   user: [],
//   isAuthenticated: true,
//   error: null
// };

// export const userReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case ActionTypes.LOGIN_SUCCESS:
//       return {
//         ...state,
//         user: action.payload,
//         isAuthenticated: true,
//         error: null
//       };
//     case ActionTypes.LOGIN_FAILURE:
//       return {
//         ...state,
//         user: null,
//         isAuthenticated: false,
//         error: action.payload
//       };
//     case ActionTypes.LOGOUT:
//       return {
//         ...state,
//         user: null,
//         isAuthenticated: false,
//         error: null
//       };
//     default:
//       return state;
//   }
// };




import { ActionTypes } from "../Constant/ActionTypes";

const initialState={
 user: []
}

export const userReducer = (state = initialState, {type, payload}) =>{
switch(type) {
 case ActionTypes.SET_USER:
  return {...state,user:payload};
  default:
   return state;
}
}