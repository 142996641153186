import * as React from 'react';
import './Faq.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SimpleAccordion() {
  return (
    <div className='faqContainer'>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is MBBPIL?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          MBBPIL is Millionaires and Billionaires Business Partners International Limited. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>What does MBBPIL do? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          An organization set up to assist its registered members to actualize their business visions, live healthy and have financially security. It also helps students in tertiary institutions and low income workers to have financial freedom by earning money from their referrals and bonuses on the Welcome Back Platform. 
         Our long-term vision is to 
<li>👉	reduce the level of poverty in Nigeria.</li> 
<li>👉	encourage/expand agricultural practice using the latest technology and techniques available.</li>
<li>👉	setup agricultural industries in partnership with our members in every local government area.</li>
<li>👉	provide social services.</li>

          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is the Membership Platform?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          This the platform on which interested individuals can join to become Members of MBBPIL.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is the Welcome Back Platform?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          On the Welcome Back platform, students in tertiary institutions, low income workers, apprentices and other individuals with set financial goals can register to become Participants.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>How do I become a member of MBBPIL?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          To do this, you will fill and submit the online Registration form and pay a non-refundable Registration fee of Fifty thousand Naira (N50,000) only. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>How do I become a Participant on the Welcome Back Platform?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          To do this, you will fill and submit the online Registration form along with the payment of a non-refundable Registration fee of Five thousand Naira (N5,000) only. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>As a Member or Participant how do I receive my Referral and Graduating bonus?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Referral and Graduating bonuses earned are paid monthly into the bank accounts of Members/Participants on the platform. Payments will be made on a set day of the month. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Can I receive money in lieu of the awards listed on the MBBPIL Platforms?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Awards cannot be monetized. They are gifts earned by Members/Participants as they move up the matrix on the Membership/Welcome Back platforms.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Where and when will the awards be given out to deserving Members/Participants?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Members/Participants graduating from an Award stage will receive the indicated gifts at Award Ceremonies during scheduled MBBPIL Business Meetings/Conferences/Award Nights
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>How do I earn referral bonus on the MBBPIL platforms?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          A Referral bonus is earned when the people you introduced register online using your unique referral code and make payment of the non-refundable registration fee of ₦50,000(Membership/Main Platform) or ₦5,000(welcome Back Platform). Only Members(paid registrants on the Main/Membership platform) or Participants(paid registrants on the Welcome Back platform) can earn referral or graduating bonuses on the MBBPIL platform.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is a Matrix bonus?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Automatically earned on completion of the activities in a particular stage and movement to a higher stage or level. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is a Graduating bonus?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The Graduating bonus is the cash sum earned when a member or participant moves from a lower to a higher stage on the matrix. It is automatically earned. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>How do I know the stage in which I am on the MBBPIL platforms?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Both the Membership Platform (for Members) and the Welcome Back Platform (for Participants) have a SIGN IN link that allows them to access their individual accounts and view their account information. (Member’s/Participant’s current stage, bonuses due or paid, awards due or received and other personal details).
          </Typography>
        </AccordionDetails>
      </Accordion>

    </div>
  );
}