import React from 'react'
import './Terms.css'

const Terms = () => {
  return (
    <div className='termsContainer'>

     <h1 className='termsHead'>Terms and Conditions – MBBPIL</h1>
     <p>
     Access and use of MBBPIL (web app/site and its contents) is subject to the present Terms and Conditions. Please read our Terms and Conditions, carefully before using, and/or accessing our Web app/site in any way whatsoever. By registering and/or using the Web app/site you explicitly acknowledge and agree to be bound by these Terms and Conditions and undertake to comply with them unreservedly, including our Privacy Policy, Payment Terms and Conditions, Promotional Terms and Conditions and Referral Terms and Conditions. If you do not agree and/or accept these Terms and Conditions, you should not access and/or use our Web app/site and/or our Services.
     </p>
     <br/>
     {/* <br/> */}
     <h3>
MILLIONAIRES & BILLIONAIRES BUSINESS PARTNERS INTERNATIONAL LTD. 
     </h3>
      
<p>

MBBPIL is actually born out of the present situation in Nigeria where absolutely all consumables are imported to us as a nation including food items. Most people (if not everybody) in Nigeria depend on the National budgets. Having reviewed the lifestyle of average Nigerian men and women either as workers or professionals, it has obviously become very difficult to make ends meet, except they go the extra mile. Our personal needs, family needs, extended family needs, social needs and many unforeseen circumstances place so much pressure on our meager incomes that it becomes almost impossible for Nigerians to plan for their futures.
Because of these difficulties facing Nigerians, we have decided to break the yoke of hardship in the form of a <strong>“Plan B Project”</strong>. These plans are designed to take every registered member of MBBPIL to their set goals or dreams which we call <strong>ELDORADO</strong> . Now that pension is no longer reliable, the <strong>“Plan B Project”</strong>  could be the only saving grace for workers and professionals to achieve their goals and dreams.
<br/><br/>

We at MBBPIL have observed that every man wants to:
<ul>

<li>👉live and keep a healthy lifestyle</li>
<li>👉have financial security</li>
<li>👉fulfill self-actualization.</li>

</ul><br/>
Therefore, if you are yearning to achieve any of these objectives (whether young or old, student or civil servant, retiree or businessman), you can fulfill your goals by joining us and be a part of this economic and industrial revolution of our time. 
<br/>
The Millionaires and Billionaires Business Partners International Limited have designed platforms to actualize these objectives in the life of our <strong>Members</strong> .<br/><br/>

Our major objectives are <br/>
1.	Industrialization (industrial growth)<br/>
2.	Agro-economic growth<br/>
3.	Social services<br/>
These objectives will be achieved through the deployment of multi-level co-operative techniques and technology to actualize the business dreams and visions of our members.<br/><br/>

<strong>Our Platforms</strong><br/>

In view of the above, two platforms have been designed to achieve these attainable objectives. These are:<br/>
1.	The Main MBBPIL Platform (Membership Platform)<br/>
2.	The Welcome Back Platform<br/>
The use of our Services requires you to create a Membership Account and explicitly and irrevocably accept these Terms and Conditions by checking the relevant box on the registration form. <br/>
</p>
<br/>

<h3 className='modification'>MODIFICATION</h3>
<strong>MBBPIL</strong>  (web app/site and its contents) reserves the right to modify and update the Terms and Conditions, access to and contents of its Website from time to time. If the Terms and Conditions are modified, Members will be informed by means of an appropriate message, in advance of such changes taking effect.<br/>
Any connection to and/or use of our Services/Web app/site by Members after being notified of changes to our Services/Web app/site or amendments to the Terms and Conditions will be deemed to be acceptance of the changes made.<br/><br/>

<h3 className='modification'>PROTECTION OF PERSONSAL DATA AND COOKIES</h3>
We will take the utmost care to protect the personal information you provide to us.<br/><br/>
<h3 className='modification'>REGISTRATION</h3> 
To access and use the Services available on the Web app/site requires you to create a personal Membership Account using the registration form. <strong>It is impossible to be a Member without a fully paid Membership Account.</strong> <br/>
<strong>The Membership Registration Fee is a non-refundable sum of Fifty Thousand Naira (N50,000) only.</strong><br/>
To be eligible to register an account on the Web app/site and/or use our Services you must:<br/>
1. Be a physical person with legal capacity, this means you are of sound mind and capable of taking responsibility for your own actions.<br/>
2. Have a valid bank account, credit or debit card or alternative funds payment/receivable method that is approved by the relevant regulatory authorities.<br/><br/>

To create an account, simply click on the <strong>“Register”</strong>  or <strong>“Register Now”</strong>  button and follow the instructions on the screen. The prospective Member must supply all relevant information.<br/>
Once the information has been entered, you will be asked to accept the Terms and Conditions and our Privacy Policy by checking the box next to <strong>“I HAVE READ AND ACCEPT THE TERMS AND CONDITIONS AND THE PRIVACY POLICY OF MBBPIL</strong> (web app/site and its contents)”. <br/>
During the registration, the prospective member will be sent an email containing a hyperlink on which to click to confirm his/her email address and activate his/her account.

<h3 className='modification'>MEMBERS ACCOUNT AND PASSWORD</h3>
You must keep your password and all account details secret and confidential as you are solely responsible for the use of the Web app/site and/or Services made through your Membership Account and any other activities taking place through your account.<br/>
If you forget your password, click on the <strong>“FORGOT MY PASSWORD”</strong>  button on the Website and enter the email address associated with your account. MBBPIL (web app/site and its contents) will then send you an email with a link allowing you to generate a new password.<br/>
You undertake to notify MBBPIL (web app/site and its contents) immediately if you believe that there is an unauthorized use of your Membership Account or your password, or if there is any security breach.<br/>
MBBPIL (web app/site and its contents) reserves the right to demand that you change your password if it believes that it no longer ensures adequate security. MBBPIL accepts no liability for loss or harm suffered by the Member as a result of an unauthorized use of his/her connection details and/or Account by third parties, even without the Member’s knowledge.

<h3 className='modification'>OBLIGATIONS RELATING TO THE USE OF THE WEBSITE</h3>

By using the Web app/site and/or the Services, the prospective member undertakes:<br/>
1. To use the Web app/site or its Services only for the purposes authorized by and in accordance with (i) the Terms and Conditions and by (ii) any law, regulation, practice or directive issued by MBBPIL.<br/>
2. Not to disrupt or interfere with the security or to participate in any other form of abuse of the Web app/site, the Services, the system resources, accounts, servers or networks connected to or accessible via the Web app/site or the related or linked websites<br/>
3. Not to create or use a false identify on this Web app/site.<br/>
4. Not to disseminate false information or illegal notices.<br/>
5. Not to attempt to obtain unauthorized access to the Web app/site.<br/>
6. Not to use the Website for purposes that are illegal or liable to harm the reputation of MBBPIL (or third parties).<br/>
7. Not to modify or attempt to modify, by means of any processes or tools whatsoever, the web app/site software or Services.<br/>
8. Not to harm the intellectual property rights of MBBPIL, the Brand Owner or third parties.<br/>
9. Not to reproduce all or part of the content of the Web app/site and/or Services on any medium whatsoever without the authorization of MBBPIL.<br/>
10. Not to upload, send transmit by email or in any other way any content that might be illegal, harmful, threatening, abusive, constituting harassment, defamatory, vulgar, obscene, pornographic, threatening to other people’s privacy, hateful, racist or objectionable in any other way.<br/>
11. To comply with any tax liabilities.<br/>
12. Not to authorize a third party to use your Membership Account, or to use payment methods, credit, debit cards or the user account of a third person or another Member.<br/>
13. To respond as soon as reasonably possible to any requests made by MBBPIL relating to your Membership Account and to communicate to MBBPIL all necessary details.<br/><br/>
Furthermore, you agree that you are solely responsible for the actions and communications performed or transmitted via the Website from your Membership account.

<h3 className='modification'>MEMBER GUARANTEES</h3>
The Member declares and warrants that:<br/>
1. He/She has read, understood and agrees to the present Terms and Conditions, the Privacy Policy, the Bonus Terms and Conditions (on the web app/site) and accepts the rules pertaining to our Services as made available on the Web app/site.<br/>
2. The information and personal data supplied is accurate, truthful and complete in all respects, and to keep their Account details up to date.<br/>
3. The Member undertakes to indemnify, defend and exonerate MBBPIL, its directors, employees, representatives and suppliers, as well as any possible third‐party, against any possible losses, expenses, harm and costs, including legal fees arising from any infringements by you of these Terms and Conditions.<br/>


<h3 className='modification'>OUR COMMITMENT</h3>
MBBPIL (web app/site and its contents) is committed to providing the highest quality customer service and delivery.

<h3 className='modification'>ACCOUNT CLOSURE BY THE MEMBER </h3>
If you want to close your account, please send an email to our Membership Support Team. Such termination will take effect upon our closing of your account, which shall occur within seven (7) business days after our receipt of your email. Your email will be deemed “received” only after a confirmation email is sent to you, and we will start counting the 7-day period from this point. During the pre-closing period, you shall remain responsible for any activity on your account.


<h3 className='modification'>ACCOUNT SUSPENSION OR CLOSURE BY MBBPIL </h3>
MBBPIL (web app/site and its contents) at its sole discretion may close or suspend your account at any time and for any reason, under the following circumstances:<br/>
1. The Member’s account remains inactive for an uninterrupted period of twelve (12) months or more.<br/>
2. MBBPIL believes that the Member has infringed or breached, in whole or in part, one or more provisions of these Terms and Conditions.<br/>
3. The Member has not provided accurate details on registration and his/her bank account details cannot be verified.<br/>
4. MBBPIL reasonably believes that you have used the Web app/site and/or the Services in an unfair manner, have deliberately cheated or taken unfair advantage of us or any of our Members.<br/>
5. If MBBPIL is requested to do so by any law enforcement agency, regulatory authority, or court order.<br/>

<h3 className='modification'>COMPLAINTS AND DISPUTES</h3>
In the event of any complaint relating to the use of our Web app/site or Services including but not limited to the Terms and Conditions, it should be addressed by email to our Membership Support Team. Please ensure to provide as much details as possible regarding your complaint, including but not limited to a description of your complaint, date the events occurred, attachment or screenshots of the issues and/or any other evidence to help us solve your complaint.<br/><br/>
Upon receipt of your complaint, we will deal with it as quickly and reasonably possible. Complaints are handled by our Membership Support Team and they shall keep the member informed about the state of the complaint. We will do our best to close complaints received from our Members within 14 days of receipt of the complaint. 

<h3 className='modification'>GOVERNING LAW AND JURISDICTION</h3>
Our Terms and Conditions are subject to Nigerian laws, having sole and exclusive competence to adjudicate any dispute concerning MBBPILTD (web app/site and its contents) and its members and the interpretation of these Terms and Conditions in Nigerian courts.
    </div>
  )
}

export default Terms