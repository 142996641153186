import React, { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../shared/Button';
import swal from 'sweetalert';
import '../../../shared/CSS/Login.css';

const Login = () => {

   const logregStyle= {
  width:'150px',
  height:'40px',
  background:'green',
  color:'#000',
  fontSize:'1.2rem',
  borderRadius:'8px',
  textAlign:'center',
  border:'none',
  cursor:'pointer',
  transition:'1000s'
 }

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // useEffect(() => {
  //   // Check if a token exists in local storage
  //   const token = localStorage.getItem('token');
  //   if (token) {
  //     // Process the token
  //     const decodedToken = jwt_decode(token);
  //     console.log(decodedToken);
  //     // Perform any necessary actions with the token
  //     navigate('/admin');
  //   }
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send a request to your backend to authenticate the user
    const response = await fetch('https://mbbpil-admin.vercel.app/admin/adminLogin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    const data = await response.json();

    if (response.ok) {
      const { token } = data;
      // Decode the JWT token to extract user information
      const decodedToken = jwt_decode(data.token);
      console.log(decodedToken);
      console.log(data)
      // Save the token in local storage or a state management tool (e.g., Redux)

      localStorage.setItem('token', token);
      // swal("Successful!" ,`${response.message}`);
      swal("Login Successful!");
      navigate('/admindashboard')
    } else {
      // Handle authentication error
      // console.log(err);
      swal("Failed");
    }
  };

  return (
    <form onSubmit={handleSubmit} className='formContainer'>
      <h1 className='form'>LOGIN AS ADMINISTRATOR</h1>
      <div>

      <input
        type="email"
        placeholder="Email"
        className="login-email-input"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        />
        </div>
        <div>

      <input
        type="password"
        placeholder="Password"
        className="login-password-input"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        />
        </div>

        <div className="buttonFlex">
         <div>
          <Button text={"Login"}style={logregStyle}> 
          </Button>
         </div>
        </div>
        
    </form>
  );
};

export default Login;
