export const mainPageData = [
                    {id:0, 
                      stage: 'STAGE 0', 
                    stageHead:'WELCOME', 
                    activitiesPara:'This is the entrance stage where the matrix starts filling up.', 
                    stageList1:'👉It is the gateway through which you become a member of MBBPIL', 
                    benefitList1:'👉Referral Bonus of ₦10,000 each for 2 persons referred - ₦20,000',
                    benefitList2:'👉Graduating Bonus - ₦10,000', 
                    benefitList3:'👉Total - ₦30,000' ,
                  },
                  
                    {id:1, 
                      stage: 'STAGE 1', 
                    stageHead:'ASSOCIATE', 
                    activitiesPara:'At this stage, members:', 
                    stageList1:'👉Start attending Health Seminars',
                    stageList2:'👉Receive a MBBPIL membership identity card', 
                    stageList3:'👉Are advised to register under the National Health Insurance Scheme', 
                    benefitList1:'👉Activities  Bonus - ₦50,000',
                    benefitList2:'👉Graduating Bonus - ₦50,000', 
                    benefitList3:'👉Total – ₦100,000'
                  
                  },
                  
                    {id:2, 
                      stage: 'STAGE 2', 
                    stageHead:'HONOURABLE', 
                    activitiesPara:'At this stage, members:', 
                    stageList1:'👉will be registered into a Health Organization',
                    stageList2:'👉The registration fee will be deducted from their bonuses.', 
                    benefitList1:'👉Activities  Bonus - ₦400,000',
                    benefitList2:'👉Graduating Bonus - ₦100,000', 
                    benefitList4:'👉Laptop Award - ₦200,000', 
                    benefitList3:'👉Total - ₦700,000' 
                  },
                  
                    {id:3, 
                      stage: 'STAGE 3', 
                    stageHead:'CHAIRMAN', 
                    activitiesPara:'At this stage, members:', 
                    stageList1:'👉Start attending Small/Medium scale enterprise seminars organized by MBBPIL', 
                    stageList2:'👉Are trained in the production and management of their business goals', 
                    benefitList1:'👉Activities  Bonus - ₦700,000',
                    benefitList2:'👉Graduating Bonus - ₦200,000', 
                    benefitList4:'👉Gift Award  - ₦600,000' , 
                    benefitList3:'👉Total – ₦1,500,000' },
                  
                    {id:4, 
                      stage: 'STAGE 4', 
                    stageHead:'MINISTER', 
                    activitiesPara:'At this stage, members:', 
                    stageList1:'👉Start attending industrial production and management workshops related to their business goals organized  by the Federal Ministry of Trade and Industry', 
                    benefitList1:'👉Activities  Bonus - ₦1,700,000',
                    benefitList2:'👉Graduating Bonus - ₦300,000', 
                    benefitList4:'👉Car award - ₦6,000,000' , 
                    benefitList3:'👉Total – ₦8,000,000' },
                  
                     {id:5, 
                      stage: 'STAGE 5', 
                     stageHead:'EXECUTIVE', 
                     activitiesPara:'At this stage, members:', 
                     stageList1:'👉Are qualified to setup/start their business goals',
                     stageList2:'👉Can apply for a grant of up to 40 Million Naira to start up their businesses in partnership with MBBPIL', 
                     stageList3:'👉Are on their way to becoming successful industrial partners with MBBPIL', 
                     stageList4:'👉Are registered into MBBPIL Social Club', 
                     benefitList1:'👉Activities Bonus  – ₦4,000,000',
                     benefitList2:'👉Graduating Bonus  – ₦1,500,000',
                     benefitList3:'👉MBBPIL Social Club  – ₦1,000,000',
                     benefitList4:'👉SUV Award - ₦30,000,000', 
                     benefitList5:'👉Total – ₦36,500,000'},
                  
                    {id:6, 
                      stage: 'STAGE 6', 
                    stageHead:'ELDORADO', 
                    activitiesPara:'At this stage, members:', 
                    stageList1:'👉Are Motivational Speakers encouraging others on the pathway to business success',
                    stageList2:'👉Have ample time to grow and drive their businesses in partnership with MBBPIL', 
                    stageList3:'👉Become GOLDEN CROWN PARTNERS on completion of this stage', 
                    benefitList1:'👉Activities  Bonus - ₦10,000,000',
                    benefitList2:'👉Graduating Bonus - ₦3,000,000', 
                    benefitList4:'👉Housing Award – ₦60,000,000' , 
                    benefitList3:'👉Total –₦73,000,000', 
                    benefitList5:'👉Annual Award on becoming a Golden Crown Partner (₦10,000,000 Annually for 5 years only).'},
                  ];
          
          
          export const welcomebackData= [
                    {id:0, 
                      stage: 'STAGE 0', 
                    stageHead:'WELCOME', 
                    activitiesPara:'This is the gateway through which you become a participant on this platform.', 
                    benefitList1:'👉Referral Bonus of ₦1,000 each for 2 persons referred - ₦2,000',
                    benefitList2:'👉Graduating Bonus ₦1,000', 
                    benefitList3:'👉Total – ₦3,000' 
                   },
                
                    {id:1, 
                      stage: 'STAGE 1', 
                    stageHead:'PARTICIPANT', 
                    activitiesPara:'The Participant stage',  
                    benefitList1:'👉Activities  Bonus - ₦5,000',
                    benefitList2:'👉Graduating Bonus - ₦10,000', 
                    benefitList3:'👉Total - ₦15,000' 
                   },
                
                  {id:2, 
                   stage: 'STAGE 2', 
                  stageHead:'COURAGE', 
                  activitiesPara:'The Courage stage',   
                  benefitList1:'👉Activities Bonus - ₦20,000',
                  benefitList2:'👉Graduating Bonus - ₦50,000', 
                  benefitList3:'👉Total - ₦70,000' 
                },
                
                  {id:3, 
                   stage: 'STAGE 3', 
                  stageHead:'THANK YOU', 
                  activitiesPara:'The Thank You stage',  
                  benefitList1:'👉Activities  Bonus - ₦50,000',
                  benefitList2:'👉Thank You Bonus - ₦50,000', 
                  benefitList4:'👉Total - ₦100,000' ,
                  benefitList3:'👉Gift Award - (Domestic Appliances)' 
                },
                  ];
                  