import React, { useState } from 'react';
import axios from 'axios';
import Admin from '../../../Admin/Admin';
import swal from 'sweetalert';
import '../../user/user.scss';

const MainAdminCashout = () => {
  const [formData, setFormData] = useState({
    email: '',
    amount: ''
  });

  const { email, amount } = formData;

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.put('https://mbbpil-admin.vercel.app/admin/payOutCash', { email, amount })
      .then(response => {
        // console.log(response)
        swal('Successful!', `${response.data.data}`);
      })
      .catch(error => {
        // console.log(error)
        swal('Error!', `${error.data.data}`);
      });
  };

  return (
    <div>
      <Admin>
        <div className='userBlock'>
          <h1>Main Admin Cashout</h1>
          <form onSubmit={handleSubmit}>
            <div className='userInputBlock'>
              <input
                className='userInput'
                type='email'
                name='email'
                value={email}
                onChange={handleInputChange}
                placeholder='Enter user email'
                required
              />
              <input
                className='userInput'
                type='text'
                name='amount'
                value={amount}
                onChange={handleInputChange}
                placeholder='Enter amount'
                required
              />
              <button className='userBtn' type='submit'>
                Cash Out
              </button>
            </div>
          </form>
        </div>
      </Admin>
    </div>
  );
};

export default MainAdminCashout;