import React from 'react'
import '../../../shared/PlatformHome/PlatformHome.css'
import { Link } from 'react-router-dom';
import { welcomebackData } from '../../../constants/data';
import PlatformHome from '../../../shared/PlatformHome/PlatformHome';

const WelcomeHomepage = () => {
  return (
   <section className='stage'>

<h1 className='stageHead'>WELCOME BACK PLATFORM</h1>



    {/* Fetched data from the constant folder in src */}

    <div>
    <div className='stageFlex'>

<table className='stageTable'>
      {/* <tr >
      <th></th>
      <th className='stageLists'>Membership stages</th>
      </tr> */}
      <tr>
        <td className='stageListq'>This platform introduces students in tertiary institutions and low income workers to vision building. <br />
       They start building their business visions/goals while in school, doing petty jobs or learning a trade, having in mind that they can access their financial entitlements when due and upgrade to the MBBPIL Membership Platform.
</td>
        <td className='stageLists'>The Welcome Back Platform is packaged to give financial freedom to students and low income earners as shown in the matrix below: <br />
        <li className='stageLists'>Stage 0 – Welcome </li>
        <li className='stageLists'>Stage 1 – Participant</li>
        <li className='stageLists'>Stage 2 – Courage</li> 
        <li className='stageLists'>Stage 3 – Thank You</li>
        </td>

      </tr>
      <tr>
        <td className='stageListq'> <strong>Registration</strong><br />
        You become a Participant on this platform when you:
        <br />
        <li className='listStat'>👉	Fill/complete the participant registration form </li>
        <li className='listStat'>👉	Pay a one-time non-refundable registration fee of Five Thousand Naira (N5,000) only. </li>
        <li className='listStat'>👉	Introduce other participants to the platform, earn referral bonuses and begin to move up the matrix.</li>
        </td>
        <td className='stageLists'>On completion of the Thank You stage, you are free to come back and register again (if your purpose is served) with the payment of another Five Thousand Naira (N5,000) only.</td>

      </tr>
    </table>
      </div>
    

    {welcomebackData.map((item) => (
              <PlatformHome 
              key={item.id} 
              stage={item.stage} 
              stageHead={item.stageHead} 
              activitiesPara={item.activitiesPara} 
              stageList1={item.stageList1} 
              stageList2={item.stageList2} 
              stageList3={item.stageList3} 
              stageList4={item.stageList4}
              benefitList1={item.benefitList1} 
              benefitList2={item.benefitList2} 
              benefitList4={item.benefitList4} 
              benefitList3={item.benefitList3} 
              benefitList5={item.benefitList5}/>
              ))}
              </div>

              <div className='stageBtn'>
           <Link to='/welcomebacksignup'>
        <button className='registerMe'> 
            REGISTER NOW
        </button>
           </Link>

           <Link to='/welcomebacksignin'>
        <button className='registerMe'> 
            Login
        </button>
           </Link>
          </div>

   </section>
  )
}

export default WelcomeHomepage