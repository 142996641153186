import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import store,{Persistor} from './Redux/Store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { DarkModeContextProvider } from './modules/Admin/context/darkModeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
     <PersistGate loading={<p>Loading</p>} persistor={Persistor} >
      <DarkModeContextProvider>
      <App />
      </DarkModeContextProvider>
     </PersistGate>

  </Provider>
);