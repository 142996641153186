import React from 'react'
import UserLayout from '../../../../layout/UserLayout/MainUserLayout/'
// import { useEffect, useState } from 'react';
import '../../../shared/CSS/Userdashboard.css'
import { useSelector } from 'react-redux'

const UserDashboard = () => {
    const user= useSelector( state=>state.allUser.user)
    console.log(user)
  return (
    <div>

     <UserLayout>
      <div className='dashboardContainer'>
     <h3 className='userDashboardH1'>Wallet Summary</h3>
     <div className="walletDivDiv">
      <div>
        Wallet Balance
      </div>
      <div>
        {user.wallet.$numberDecimal}
      </div>

     </div>
     <div className="earnedDiv">
      <div>Earned Bonus</div>
      <div>{user.earnedBonus.$numberDecimal}</div>
     </div>
     <div className="graduatingDiv">
      <div>Graduating Bonus</div>
      <div>{user.graduatingBonus.$numberDecimal}</div>
     </div>
     
     <div className="earnedDiv">
      <div>MBBPIL Social Club</div>
      <div>{user.mbbpil_social_club.$numberDecimal}</div>
     </div>

      <div className="earnedDiv">
      <div>Gift</div>
      <div>{user.gift.$numberDecimal}</div>
     </div>

     <div className="earnedDiv">
      <div>Laptop Award</div>
      <div>{user.laptop_award_or_equivalent.$numberDecimal}</div>
     </div>

     <div className="earnedDiv">
      <div>Car Award</div>
      <div>{user.car_award.$numberDecimal}</div>
     </div>

     <div className="earnedDiv">
      <div>House Award</div>
      <div>{user.housing_award.$numberDecimal}</div>
     </div>


     <div className="earnedDiv">
      <div>SUV Award</div>
      <div>{user.suv_award.$numberDecimal}</div>
     </div>

     {/* Referral Summary */}

     <h3 className='userDashboardH1'>Referral Summary</h3>
     <div className="walletDivDiv">
      <div>Referral count</div>
      <div>{user.referralCount.$numberDecimal}</div>

     </div>
     <div className="earnedDiv">
      <div>Referral Node</div>
      <div>{user.referralNode.$numberDecimal}</div>

     </div>


     <h3 className='userDashboardH1'>Status</h3>
     <div className="walletDivDiv">
      <div>Level</div>
      <div>{user.status}</div>
     </div>
    </div>
    </UserLayout>
    
    </div>

  )
}

export default UserDashboard