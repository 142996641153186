import React, { useState } from 'react'
import axios from 'axios';
import Admin from '../../Admin/Admin';
import '../user/user.scss';
import swal from 'sweetalert';

const MakeAdmin = () => {
                    const [email, setEmail] = useState('')

                    const handleSubmit=(e)=>{
                                        e.preventDefault();
                                        axios.put(`https://mbbpil-admin.vercel.app/admin/adminRoles/${email}`, `${email}`)
                                        .then((response)=>{
                                                            // console.log(response);
                                                            if (response.data.status === 200 ) {
                                                              swal('Success!', `${response.data.status}`);
                                                            }
                                        })
                                        .catch(error=>{
                                                            // console.log(error)
                                                            swal('Error!', `${error}`)
                                        })
                    };
                    const handleInputChange= (event) => {
                                        setEmail(event.target.value);

                    }
  return (
    <div>
                    <Admin>

                    <div className='userBlock'>
      <h1>MAKE USER AN ADMIN</h1>
        <form onSubmit={handleSubmit}>
      <div className='userInputBlock'>
        <input className='userInput' type="email" value={email} onChange={handleInputChange} placeholder="Enter user email" />
        <button className='userBtn'>Make Admin</button>
      </div>
      </form>

      </div>

                    </Admin>

    </div>
  )
}

export default MakeAdmin;