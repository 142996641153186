import React from 'react'
import { Link } from 'react-router-dom'
import '../../shared/CSS/UpdBankSucc.css'

const UpdBankSucc = () => {
  return (
    <div>
      <div className='updContainer'>

      <h1 className='updHead'>Bank Details Successful updated!</h1>

      <Link to='/maindashboard' className='UpdLink'> GO TO DASHBOARD</Link>
      </div>
    </div>
  )
}

export default UpdBankSucc