import React from 'react'
import './widget.scss'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
// import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';


const Widget = ({title, isMoney, link, icon, amount, diff}) => {
 let data;

 // Temporary amount
//  const amount =100
//  const diff = 20

    // switch(type) {
    //  case "user":
    //   data={
    //    title:"MAIN USERS" ,
    //    isMoney: false,
    //    link: "See all main users",
    //    icon:
    //     <PersonOutlinedIcon className='icon'
    //     style={{
    //      color: "green",
    //      backgroundColor: "rgba(255, 0, 0, 0.2)",
    //     }}
        
    //     />,
    //   };
    //   break;

    //   case "order":
    //   data={
    //    title:"WELCOME USERS" ,
    //    isMoney: false,
    //    link: "See all welcome users ",
    //    icon:
    //    <PersonOutlinedIcon className='icon'
    //     style={{
    //      color: "goldenrod",
    //      backgroundColor: "rgba(218, 165, 32, 0.2)",
    //     }}
    //     />,
    //   };
    //   break;

    //   case "earnings":
    //   data={
    //    title:"MAIN BALANCE" ,
    //    isMoney: true,
    //    link: "View net earnings",
    //    icon:
    //     <MonetizationOnOutlinedIcon className='icon'
    //     style={{
    //      color: "crimson",
    //      backgroundColor: "rgba(0, 128, 0, 0.2)",
    //     }}
    //     />,
    //   };
    //   break;

    //   case "balance":
    //   data={
    //    title:"WELCOME BALANCE" ,
    //    isMoney: true,
    //    link: "See details",
    //    icon:
    //     <AccountBalanceWalletOutlinedIcon className='icon'
    //     style={{
    //      color: "purple",
    //      backgroundColor: "rgba(128, 0, 128, 0.2)",
    //     }}
    //     />,
    //   };
    //   break;
    //   default:
    //    break;
    // }

    
  return (
    <div className='widget'>
     <div className="left">
      <span className="title">{title}</span>
      <span className="counter">{isMoney && "N"} {amount}</span>
      <span className="link">{link}</span>

     </div>
     <div className="right">
      <div className="percentage positive">
       <KeyboardArrowUpIcon/>
       {diff}%</div>
     {icon}
     </div>
     
    

    </div>
  )
}

export default Widget