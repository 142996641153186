import React from 'react'
import '../../shared/CSS/ResetPassword.css'
import { useFormik } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import Button from '../../../shared/Button';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const ResetPasswordw = () => {

    const confirmStyle= {
          width:'170px',
          height:'40px',
          background:'green',
          color:'#000',
          fontSize:'1.2rem',
          borderRadius:'8px',
          textAlign:'center',
          border:'none',
          cursor:'pointer',
          transition:'1000s',
          marginBottom:'55px'
         }

         const navigate =useNavigate()

  // Formik logic statement
  const formik = useFormik({
    initialValues: { 
      email: "", 
      password: "", 
      confirmPassword: ""
    }, 

    // Formik Submit
    onSubmit: (values) => {
      axios
        .post("https://mbbpil-welback.vercel.app/auth/welcresetpassword", values)
        .then(response => {
          // console.log(response)
          swal("Successful", `${response.data.message}`)
            navigate('/welcomebackresetpasswordconfirmation')
        })
        .catch(err => {
          // console.log(err)
          swal("Failed!", `${err.response.data.message}`)
        });
    },

    // Validation of form values
    validationSchema: Yup.object({
      email: Yup.string()
        // .email("Invalid email address")
        // .required("Email address is required")
        ,

      password: Yup.string()
        // .min(8, "Password character must have at least 8 characters")
        // .max(20, "Password character must not exceed 20 characters")
        // .matches(
        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        //   "Password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character")
        // .required("Enter a password with 8-20 characters")
        ,
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Confirm your password')
        .test('passwords-match', 'Passwords must match', function(value){
            return this.parent.password === value
        }),
    })
  });

  return (
    <div className='newPasswordContainer'>
      <form onSubmit={formik.handleSubmit}>
        <div className="newPassDiv">
        <h1 className='passRe'>WELCOMEBACK PLATFORM PASSWORD RESET</h1>
          <div className="input-wrap">
            <label
              className={
                formik.touched.email && formik.errors.email
                  ? "small_tag"
                  : "input_label"
              }
              htmlFor="email"
            >
              {formik.errors.email
                ? formik.errors.email
                : "Enter Email address"}
            </label>
            <input
              className="input_type"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              id="email"
              name="email"
              placeholder="email"
              onBlur={formik.handleBlur}
              />
            <small className="small_tag"></small>
          </div>

          <div className="input-wrap">
            <label 
              className={formik.touched.password && formik.errors.password ? "small_tag" : "input_label"}
              htmlFor="password">
              {formik.errors.password ? formik.errors.password : "Enter New Password"}
              </label>
              <input 
                         className="input_type" 
                         type="password" 
                         value={formik.values.password} 
                         onChange={formik.handleChange} 
                         id="password" 
                         name="password" 
                         placeholder="Password" 
                         onBlur={formik.handleBlur}
                       />
              <small className="small_tag"></small>
              </div>
              
              <div className="input-wrap">
        <label 
          className={formik.touched.confirmPassword && formik.errors.confirmPassword ? "small_tag" : "input_label"} 
          htmlFor="confirmPassword">
          {formik.errors.confirmPassword ? formik.errors.confirmPassword :  "Confirm Password"}
        </label>
        <input 
          className="input_type" 
          type="password" 
          value={formik.values.confirmPassword} 
          onChange={formik.handleChange} 
          id="confirmPassword" 
          name="confirmPassword" 
          placeholder="Confirm Password" 
          onBlur={formik.handleBlur}
        />
        <small className="small_tag"></small>
      </div>
    </div>
    <Button text={'Change Password'} style={confirmStyle}/>
  </form>
</div>
)
}

export default ResetPasswordw;