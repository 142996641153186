import React from 'react'
import './About.css'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <div className='aboutContainer'>
     <h2 className='aboutHead'>ABOUT US</h2>
     <br />

     <p className='aboutContent1'> <strong>Millionaires and Billionaires Business Partners International Ltd (MBBPIL)</strong> was borne out of the present economic reality in Nigeria where:
      </p>

     <ul>
      <li className='aboutListing'>👉 Most (if not all) consumables (including food items) are imported into the country.</li>
      <li className='aboutListing'>👉 Individuals, families, businesses, companies and organizations depend solely on the national budget to survive or thrive.</li>
      <li className='aboutListing'>👉 It is very difficult for an average Nigerian to make ends meet when faced with personal, family extended family and social needs along with many other unforseen circumstances.</li>
     </ul>
     <br />

     <p className="aboutContent1">
      These realities place so much pressure on our meagre individual incomes that planning for the future is almost impossible unless a person goes the extra mile to have an alternative plan/project (Plan B) to break this yoke.
     </p><br />
     <p className="aboutContent1">
      Our vision is to encourage members to set achievable goals for themselves that will lead to:
     </p>

     <ul>
      <li className='aboutListing'>👉 Start-up and growth of profitable business ventures in partnership with MBBPIL.</li>
      <li className='aboutListing'>👉 A healthy lifestyle.</li>
      <li className='aboutListing'>👉 Adequate financial lifestyle (security).</li>
     </ul>
     <br />

     <p className="aboutContent1">
      We set every member on the pathway to self actualization from <strong>"ASSOCIATE"</strong> until they attain the status of <strong>"ELDORADO"</strong> as shown on the <strong>Membership (Main) Platform</strong>
      <br /><br />
      On the <strong>Welcome Back Platform</strong>, participants move up a matrix from <strong>Participant</strong> stage to <strong>Thank You</strong> stage.
      <br /><br />
      <strong>Referral</strong>, <strong>Activities</strong> and <strong>Graduating Bonuses</strong> as well as <strong>Gift/Cash Awards</strong> are recieved at different levels of these platforms.
     </p>
     <br />

     <Link to='/getstarted'>
     <p className="partner">
      Be a partner/Sign Up!
     </p>
     
     </Link>
    </div>
  )
}

export default About