import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoutes = () => {
                    const auth = localStorage.getItem('token')
                    const role  = localStorage.getItem('roles')
  return (
                    auth ? <Outlet/> : <Navigate to='/welcomebacksignin'/>
                    // auth && role === 'user' ? <Outlet/> : <Navigate to='/welcomebacksignin'/>

  )
}

export default ProtectedRoutes;