import React, {useState} from 'react'
import Button from '../../../../shared/Button'
import './Contact.css'
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneSquareAlt } from "react-icons/fa";
import axios from 'axios';
import swal from 'sweetalert';




const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    axios.post("https://mbbpilpartners.cyclic.app/auth/sendContact", { name, email, message })
    .then(res=>{
      console.log(res)
      swal('Successful' `${res.data?.message}`)
    })
    .catch(err =>{
      console.log(err)
      swal('Failed' `${err.data?.message}`)
    })
  };


 const contactBtn= {
  width: '180px',
  height: '70px',
  background: 'black',
  color:'white',
  fontSize: '24px',
  borderRadius:'8px',
  border:'none',
  cursor:'pointer',
 }
  return (
    <div className='contactContainer'>
     <h1 className='contactHead'> CONTACT US</h1>

     <div className='contactDiv'>
      <div className='contactDiv1'>
       {/* <ImLocation2/> */}
       <p className='locationPara'><FaMapMarkerAlt className='faIconss'/>Plot 2 Block 1, FSSS Dove Estate, Oke-Odo, Abule-Egba, Lagos, Nigeria</p>
       <p className='emailPara'><FaEnvelope className='faIconss'/>support@mbbpilpatners.com</p>

       <p className='phonePara'><FaPhoneSquareAlt className='faIconss'/>+234(0)8025064226 , +234(0)8055083595 , +234(0)9059249884</p>
       <div>
        <h2 className='headF'>Follow us</h2>
        <a href='https://www.instagram.com' target='_blanck'><FaInstagram className='faIcons'/></a>
        <a href='https://www.twitter.com' target='_blanck'><FaTwitterSquare className='faIcons'/></a>
        <a href='https://www.facebook.com' target='_blanck'><FaFacebookSquare className='faIcons'/></a>
        {/* <a href=''/><FaInstagram className='faIcons'/> */}

       </div>
      </div>
      <div className='contactDiv2'>
       <form onSubmit={handleSubmit} className='formCont'>
        <div className='contactBlock'>
         <label htmlFor='Name' className='labell'>Name</label>
         <input 
         type='text'
         className='contactInput'
         value={name} onChange={(e) => setName(e.target.value)}
         />
        </div>


        <div className='contactBlock'>
         <label htmlFor='Email' className='labell'>Email</label>
         <input 
         type='email'
         className='contactInput'
         value={email} onChange={(e) => setEmail(e.target.value)}
         />
         </div>


        <div className='contactBlock'>
         <label htmlFor='Message' className='labell'>Message</label>
         <textarea className='contactInput' value={message} onChange={(e) => setMessage(e.target.value)} cols={30}/>
         </div> 

         <div className='contactBtnDiv'>
          <Button text='Submit' style={contactBtn}/>
         </div>


       </form>

      </div>
     </div>

    </div>
  )
}

export default Contact;