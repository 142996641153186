import React, { useState } from 'react'
import '../../shared/CSS/Signup.css'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import {Link} from 'react-router-dom';
import axios from 'axios';
import Button from '../../../shared/Button';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import swal from 'sweetalert';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


const formSchema= Yup.object().shape({
    fullName: Yup.string()
 .required("Enter your Full Names")
 ,
 phone: Yup.string()
 .matches(phoneRegExp, 'Phone number is not valid')
   .min(10, "too short")
   .max(11, "Good")
 .required("Enter Phone number")
 ,
 address: Yup.string()
   .required("Enter a valid address")
 
 ,
 email: Yup.string()
     .email("Provide a valid email address")
     .required("Enter an email address")
 ,
 referralCode: Yup.string()
//    .required("Dont miss one digit if not availabile type nil")
 
 ,
 password: Yup.string()
 //  .min(8, "Password character must have at least 8 characters")
    //  .max(20, "Password character must not exceed 20 characters")
    //  .matches(
    //      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //      "Password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character")
    .required("Enter a password with 8-20 characters")
    ,
    confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Confirm your password')
    .test('passwords-match', 'Passwords must match', function(value){
        return this.parent.password === value
    })
    // ,
    // agreement : Yup.string().required('Please click the checkbox to take you in')
});
const registerStyle={
    width:'150px',
    height:'40px',
    background:'green',
    color:'#fff',
    fontSize:'1.2rem',
        borderRadius:'8px',
        textAlign:'center',
        border:'none',
        cursor:'pointer',
        transition:'1000s'
    }
    
    const Signup = () => {

      const [loading, setLoading] = useState(false);
        
        const navigate =useNavigate();

        // const [responseState, setResponseState] = useState();



    // API consumption

    const handleOnSubmit = (values, actions) => {
      axios
      .post("https://mbbpils-xi.vercel.app/auth/signup" , values)
      .then(response => {
        // console.log(response);
        if (response && response.status ===201) {
          swal("Registeration is Successful");
          navigate('/mainsuccess');
        } 
      })
      .catch(error => {
        // console.log(error)
        // if (error.response && error.response.status === 500) {
          swal("Failed", `${error.response.data.message}`);
        // } 
      });
    };

  

  return (
    <div className='signupFormContainer'>

    <h1 className='signupHead'>Main Platform Registration</h1>

     <p className="p-head">
            
            Registration will take a couple of minutes
        </p>
        
        <Formik
 initialValues = {{
     fullName:"", 
    email: "", 
    phone:"", 
    password: "", 
    confirmPassword: "", 
    referralCode:"",
    address:"", 
    // agreement:"",
}}
onSubmit={handleOnSubmit}
validationSchema={formSchema}
>
{({ isSubmitting }) => (
        <Form className="signup_form" noValidate>
        <div className="firstdiv">
           <div className="input-wrap">
            <label htmlFor="fullName" className='input_label'>Full Names:</label>
                <Field className="input_type" id="fullName" type="text" name="fullName" placeholder="Full Names"/>
                <ErrorMessage name="fullName" className="errorMsg" component="p" />
            </div>

            <div className="input-wrap">
            <label htmlFor="email" className='input_label'>Email:</label>
                <Field className="input_type" id="email" type="email" name="email" placeholder="Enter email address..." />
                <ErrorMessage name="email" className="errorMsg" component="p" />
                </div>
           </div>
         
         
         <div className="userMail">
         {/* Address input */}
         <div className="input-wrap">
                <label htmlFor="address" className='input_label'>Address:</label>
                <Field className="input_type" id="address" type="text" name="address" placeholder='Enter a valid address' />
                <ErrorMessage name="address" className="errorMsg" component="p" />
            </div>

          {/* Phone Number */}
            <div className="input-wrap">
                <label htmlFor="phone" className='input_label'>Phone Number:</label>
                <Field className="input_type" id="phone" type="text" name="phone" placeholder='Enter Phone Number' />
                <ErrorMessage name="phone" className="errorMsg" component="p" />
            </div>
         </div>

        {/* Password and confirm password input div */}
         <div className="passDiv">
            <div className="input-wrap">
                <label htmlFor="password" className='input_label'>Enter Password:</label>
                <Field className="input_type" id="password" type="password" name="password" placeholder='Enter Password' />
                <ErrorMessage name="password" className="errorMsg" component="p" />
            </div>
    
            <div className="input-wrap">
                <label htmlFor="confirmPassword" className='input_label'>Confirm Password:</label>
                <Field className="input_type" id="confirmPassword" type="password" name="confirmPassword" placeholder='Confirm Password' />
                <ErrorMessage name="confirmPassword" className="errorMsg" component="p" />
            </div>
         </div>

         {/* REFERRAL CODE */}
         <div className="referralcodess">

         <div className="input-wrap">
                <label htmlFor="referralCode" className='input_label'>Enter Referal code:</label>
                <Field className="input_type" id="referralCode" type="text" name="referralCode" placeholder='Enter Referral Code' />
                <ErrorMessage name="referralCode" className="errorMsg" component="p" />
            </div>
         </div>

         {/* REQUIRED CHECKBOX */}

         <input type="checkbox" name="agreement" id="" className='checkbox'/> I agree to MBBPIL <Link to='/Terms&condition' className='tc'>Terms and condition</Link>
         <ErrorMessage name="agreement" className="errorMsg" component="p" />

            <div className="signupButton">
            
            <Button text={loading ? "Registering" : "Register"} style={registerStyle} loading={loading}>
            </Button>
            </div>
            
        </Form>

        )}
        </Formik>
    {/* <div>
        <a href='/Form' className='already'>Already have an account</a>
    </div> */}
    </div>

   
  )
}

export default Signup