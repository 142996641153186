import React from 'react'
import '../../shared/CSS/Success.css'
import { Link } from 'react-router-dom'

const Success = () => {
  return (
    <div className='successDiv'>
     <h1> Kindly check your email to, then  <Link to='/mainsignin' className='sucClick'>Click Here to Login</Link></h1>
    </div>
  )
}

export default Success