import React, { Children, useEffect, useState } from 'react'
import './Admin.scss'
import Sidebar from '../Components/sidebar/Sidebar'
import Navbar from '../Components/navbar/Navbar'
import Widget from '../Components/widget/Widget'
import { GetTransactionDetails, UserCount } from '../../../api/api'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

const Admin = ({children}) => {
  const [numberOfMainUsers, setNumberOfMainUsers] = useState("")
  const [numberOfWelcomeBack, setNumberOfWelcomeBack] = useState("")
  const [welcomeUserCount, setWelcomeUserCount] = useState("")
  const [mainUserCount, setMainUserCount] = useState("")




    useEffect(() => {
  GetTransactionDetails((res) => {
    console.log(res)
    if(res.status === 200) {
setNumberOfMainUsers(res?.data?.numberOfMainUsers[0]?.amount?.$numberDecimal)
setNumberOfWelcomeBack(res?.data?.numberOfWelcomeBack[0]?.amount?.$numberDecimal)
}
  })
  UserCount((res) =>{
    console.log(res)
    if(res.status === 200) {
setMainUserCount(res?.data?.numberOfMainUsers)
setWelcomeUserCount(res?.data?.numberOfWelcomeBack)
    }
  })


}, [])

  
  return (
    <div className='home'>
      <Sidebar/>
      <div className="adminContainer">
        <Navbar/>

        {/* widget */}
        <div className="widgetContainer">
          <Widget title='MAIN USERS' diff={20} isMoney={false} amount={mainUserCount} link="See all main users" icon={<PersonOutlinedIcon className='icon'
     style={{
    color: "green",
    backgroundColor: "rgba(255, 0, 0, 0.2)",
      }} />}/>
          <Widget title='WELCOME USERS' diff={20} isMoney={false} amount={welcomeUserCount} link="See all Welcome users" icon={<PersonOutlinedIcon className='icon'
     style={{
    color: "green",
    backgroundColor: "rgba(255, 0, 0, 0.2)",
      }} />}/>

          <Widget 
          title='MAIN TRANSACTIONS' 
          diff={20} 
          isMoney={false} 
          amount={numberOfMainUsers} link="See all main users" 
          icon={<AccountBalanceWalletOutlinedIcon className='icon'
         style={{
          color: "purple",
          backgroundColor: "rgba(128, 0, 128, 0.2)",
         }}
         />}/> 

          <Widget 
          title='WELCOME TRANSACTIONS' 
          diff={20} 
          isMoney={false} 
          amount={numberOfWelcomeBack} 
          link="See all main users" 
          icon={<AccountBalanceWalletOutlinedIcon className='icon'
         style={{
          color: "purple",
          backgroundColor: "rgba(128, 0, 128, 0.2)",
         }}
         />}/>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Admin