import React from 'react'
import UserSideBarw from '../../../modules/welcomeback/views/UserDashboard/UserSideBar/UserSideBar'
import '../userlayout.css'
import { useSelector } from 'react-redux'

const UserLayoutw = ({children}) => {
  const user = useSelector(state => state.allUser.user)
  return (
    <div className='subLayoutContainer'>
      <p className='subLayoutP'> WELCOME BACK, {user.fullName} !</p>
     <div className="flexDash">
     <div className='usersidediv'>
      <UserSideBarw/>
     </div>
     <div className='spa'>
     {children}
     </div>
    </div>
    </div>
  )
}

export default UserLayoutw;