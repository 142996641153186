import React from 'react'
import UserSideBar from '../../../modules/main/views/UserDashboard/UserSideBar/UserSideBar'
import '../userlayout.css'
import { useSelector } from 'react-redux'
// import Navbar from '../../Navbar/NavBar'
// import Footer from '../../Footer/Footer'

const UserLayout = ({children}) => {
  const user = useSelector(state => state.allUser.user)
  return (
    <div>
    <div className='subLayoutContainer'>
      <p className='subLayoutP'> WELCOME, {user.fullName}!</p>
     <div className="flexDash">
     <div className='usersidediv'>
      <UserSideBar/>
     </div>
     <div className='spa'>
     {children}
     </div>
    </div>
    </div>
    </div>
  )
}

export default UserLayout;