import React from 'react'
import { Link } from 'react-router-dom'
import './Getstarted.css'
import Carousell from '../../../../shared/carousel/Carousel'

const Getstarted = () => {
  return (
    <div className='loginBtnDiv'>

      <Carousell/>

    <div className='bttt'>


     <p className='loginTo'>
      CHOOSE PLATFORM OF CHOICE 
     </p>
      <div className='bttFlex'>

     <Link to='/main'>
      <div className='welcomeb'>
       MAIN PLATFORM
       <p className='buttwords'>₦50,000</p>
      </div>
     </Link>

     <Link to='/welcomeback'>
      <div className='welcomebp'>
       WELCOME BACK PLATFORM
       <p className='buttwords'>₦5,000</p>
      </div>
     </Link>
      </div>

      {/* <p className='buttwords'>Note:   A payment of five thousand naira must be made to join the Welcomeback platform of our organization</p> */}

    </div>
    </div>
  )
}

export default Getstarted