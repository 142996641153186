import React from 'react'
import UserLayoutw from '../../../../layout/UserLayout/WelcomebackUserLayout/index'
import '../../../shared/CSS/Userdashboard.css'
import { useSelector } from 'react-redux'

const UserDashboardw = () => {
    const user= useSelector( state=>state.allUser.user)
    console.log(user)
  return (
    <UserLayoutw>
      <div className='dashboardContainer'>
     <h3 className='userDashboardH1'>Wallet Summary</h3>
     <div className="walletDivDiv">
      <div>
        Wallet Balance
      </div>
      <div>
        {user.wallet.$numberDecimal}
      </div>
     </div>
     <div className="graduatingDiv">
      <div>Graduating Bonus</div>
      <div>{user.graduatingBonus.$numberDecimal}</div>
     </div>

     <div className="earnedDiv">
      <div>Referral Bonus</div>
      <div>{user.matrixBonus.$numberDecimal}</div>
     </div>

     <div className="earnedDiv">
      <div>Thankyou Bonus</div>
      <div>{user.thankYouBonus.$numberDecimal}</div>
     </div>

     <div className="earnedDiv">
      <div>Laptop Award</div>
      <div>{user.laptopAward.$numberDecimal}</div>
     </div>

     <div className="earnedDiv">
      <div>Award</div>
      <div>{user.Award.$numberDecimal}</div>
     </div>

     <h3 className='userDashboardH1'>Referral Summary</h3>
     <div className="walletDivDiv">
      <div>Referral count</div>
      <div>{user.referralCount.$numberDecimal}</div>

     </div>
     <div className="earnedDiv">
      <div>Referral Node</div>
      <div>{user.referralNode.$numberDecimal}</div>

     </div>


     <h3 className='userDashboardH1'>Status</h3>
     <div className="walletDivDiv">
      <div>Level</div>
      <div>{user.status}</div>
     </div>
    </div>
    </UserLayoutw>
    

  )
}

export default UserDashboardw