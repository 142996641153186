import React  from 'react'
import '../../shared/CSS/Login.css'
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from '../../../shared/Button'
// import { useSignIn } from 'react-auth-kit';
import axios from 'axios';
import useToken from '../../../usetoken';
import useRole from '../../../userole';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../Redux/Action/userAction';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const Signin = () => {
  
  const navigate= useNavigate()
  const { setToken } = useToken();
  // const { setRoles} = useRole();


  const user = useSelector( state => state)
  const dispatch = useDispatch()

 const logregStyle= {
  width:'150px',
  height:'40px',
  background:'green',
  color:'#000',
  fontSize:'1.2rem',
  borderRadius:'8px',
  textAlign:'center',
  border:'none',
  cursor:'pointer',
  transition:'1000s'
 }

 const [loading, setLoading] = useState(false);
  // const signIn = useSignIn()
 const formik = useFormik({
  initialValues: {
    email: "",
    password: "",
  }
  ,
  remember: "",

  //validate form
  validationSchema: Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])/
      // )
      .required("Email address is required"),
    password: Yup.string()
      .min("8")
      .max("20 ")
      .required("Password is required"),
    remember: Yup.array(),
  }),
  //submit form
  onSubmit: (values, onSubmitProps) => {
    setLoading(true);
   
    axios.post('https://mbbpil-welback.vercel.app/auth/loginwelc', values)
      .then(response => {
        // console.log(response)
        setLoading(false);
        if (response.status===200 && response.data.user.roles==='user'){
          const token =response.data.token;
          // const roles = response.data.user.roles;
          setToken(token)
          // setRoles(roles)
          dispatch(setUser(response.data.user))
        }
        swal(`${response.data.message}`);
          if (response.data.user){
            navigate('/welcomebackdashboard')
          }
            
      })
      . catch( err => {
        // console.log(err)
        setLoading(false);
        swal("Failed", `${err.response.data.message}`);
    });
  },
});

  return (
   
     <form onSubmit={formik.handleSubmit} className='formContainer'>

      <h1 className='formwb'>Welcomeback platform Login</h1>
          <div>
          <label
            htmlFor="login-email"
            className={
              formik.touched.email && formik.errors.email
                ? "login-email-error"
                : "login-email"
            }
          >
            {formik.touched.email && formik.errors.email
              ? formik.errors.email
              : "Email Address"}
          </label>
          <input
            type="text"
            id="login-email"
            className="login-email-input"
            placeholder="Enter email address..."
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div>
          <label
            htmlFor="login-password"
            className={
              formik.touched.password && formik.errors.password
                ? "login-password-error"
                : "login-password"
            }
          >
            {formik.touched.password && formik.errors.password
              ? formik.errors.password
              : "Password"}
          </label>
          <input
            type="password"
            id="login-password"
            className="login-password-input"
            placeholder="Enter Password..."
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>

        <div className="buttonFlex">
         <div>
          <Button text={loading ? "Login in..." : "Login"}style={logregStyle} loading={loading}> 

          </Button>
         </div>
        </div>
          <div className="flexdown">

          <Link to='/welcomebacksignup'>
         <div className="login-forgot-password">Register</div>
         </Link>
        <Link to='/welcomebackforgotpassword'>
          <div className="login-forgot-password">Forgot Password?</div>
          </Link>
          </div>

     </form>

  );
}

export default Signin