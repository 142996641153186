import React, {useState} from 'react'
import '../../shared/CSS/ForgotPassword.css'
import { useNavigate } from 'react-router-dom';
import Button from '../../../shared/Button';

  const ForgotPassword = () => {

      const navigate =useNavigate()

 const logregStyle= {
  width:'150px',
  height:'40px',
  background:'green',
  color:'#000',
  fontSize:'1.2rem',
  borderRadius:'8px',
  textAlign:'center',
  border:'none',
  cursor:'pointer',
  transition:'1000s'
 }
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [serverState, setServerState] = useState();
  const handleServerResponse = (ok, msg) => {
    setServerState({ok, msg});
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("https://mbbpils-xi.vercel.app/auth/reset", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      setMessage(data.message);
    } catch (error) {
      navigate('/mainsuccess')
      // console.log(error);
    }
  };


  return (
    <div className="forgotContainer">
             <h3 className='forgetHead'>Forgot your password?</h3>
      <p className='forgotP'>Simply enter your mail below and we will send you a link to reset your password.</p>

       {/* Server Message */}
      {serverState && (
                  <p className={!serverState.ok ? "errorMsg" : ""}>
                    {serverState.msg}
                  </p>
                )}
    <form onSubmit={handleSubmit}>
      <div className="input-wrap">
        <div className='forgetInput'>
        <label htmlFor="email" className='input_label'>Email:</label>

      <input
        type="email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        placeholder="Enter your Email"
        className="input_type"
        required
        />
        </div>
      <Button style={logregStyle} text={'Reset'}/>
      {message && <p className='message'>{message}</p>}
        </div>
    </form>
    </div>
  );
};

export default ForgotPassword;