import { CircularProgressbar } from 'react-circular-progressbar'
import "react-circular-progressbar/dist/styles.css"
import './featured.scss'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { GetTransactionDetails } from '../../../../api/api';
import React, { useEffect, useState } from 'react';




const Featured = () => {
  const [numberOfMainUsers, setNumberOfMainUsers] = useState("")
  const [numberOfWelcomeBack, setNumberOfWelcomeBack] = useState("")

  useEffect(()=> {
    GetTransactionDetails((res) => {
      console.log(res)
      if(res.status === 200) {
  setNumberOfMainUsers(res?.data?.numberOfMainUsers[0]?.amount?.$numberDecimal)
  setNumberOfWelcomeBack(res?.data?.numberOfWelcomeBack[0]?.amount?.$numberDecimal)
      }
    })
  }, [])


  return (
    <div className='featured'>
     <div className="top">
      <h1>Main Balance</h1>
      <MoreVertIcon fontSize='small'/>
     </div>
     <div className="bottom">
      <div className="featuredChart">
       <CircularProgressbar  value={numberOfMainUsers} text={numberOfMainUsers} strokeWidth={5} />
      </div>
      <p className="title">Total sales made today</p>
      <p className="amount">N{numberOfMainUsers}</p>
      <p className="desc">Previous transactions processing. Last payments may not be included.</p>

      {/* <div className="summary">
       <div className="item">
        <div className="itemTitle">
         Target
        </div>
         <div className="itemResult negative">
          <KeyboardArrowDownIcon fontSize='small'/>
          <div className="resultAmount">N 120k</div>
         </div>
       </div>

       <div className="item">
        <div className="itemTitle">
         Last Week
        </div>
         <div className="itemResult positive">
          <KeyboardArrowUpOutlinedIcon fontSize='small'/>
          <div className="resultAmount">N 120k</div>
         </div>
       </div>

       <div className="item">
        <div className="itemTitle">
         Last Month
        </div>
         <div className="itemResult positive">
          <KeyboardArrowUpOutlinedIcon fontSize='small'/>
          <div className="resultAmount">N 120k</div>
         </div>
       </div>


      </div> */}

      

     </div>

    </div>
  )
}

export default Featured