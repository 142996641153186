import React, { useContext } from 'react'
import './sidebar.scss'
import logo from '../../../../asset/images/logo.jpeg'
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { DarkModeContext } from '../../context/darkModeContext';

const Sidebar = () => {

  const logOut = () =>{
    localStorage.clear()
  }

  const{dispatch} = useContext(DarkModeContext);
  return (
    <div className='sidebar'>
     <div className='top'>
      <Link to='/admindashboard'>

      <img src={logo} alt="logo" width={70} />
      </Link>
     </div>
     <div className='center'>

      <ul>

       <p className="title">MAIN</p>
        <Link to='/admindashboard'>

       <li>
        <DashboardIcon className='icon'/>
        <span>Dashboard</span>
       </li>
        </Link>

       <p className="title">LISTS</p>
       <Link to='/mainuser' style={{textDecoration:"none"}}>
       <li>
        <PersonOutlineOutlinedIcon className='icon'/>
        <span>Main Users</span>
       </li>

       </Link>
       <Link to='/welcomebackuser' style={{textDecoration:"none"}}>
       <li>
        <PersonOutlineOutlinedIcon className='icon'/>
        <span>Welcomeback Users</span>
       </li>

       </Link>
       <Link to='/makeadmin' style={{textDecoration:"none"}}>
       <li>
        <PersonOutlineOutlinedIcon className='icon'/>
        <span>Make User Admin</span>
       </li>

       </Link>
       <Link to='/mainadmincashout' style={{textDecoration:"none"}}>
       <li>
        <MonetizationOnOutlinedIcon className='icon'/>
        <span>Main Platform Admin Cashout</span>
       </li>

       </Link>

       <Link to='/welcomebackadmincashout' style={{textDecoration:"none"}}>
       <li>
        <MonetizationOnOutlinedIcon className='icon'/>
        <span>Welcomeback Platform Admin Cashout</span>
       </li>

       </Link>

       <Link to='/admin' onClick={()=>logOut()} style={{textDecoration:"none"}}>
       <li>
        <LogoutIcon className='icon'/>
        <span>Logout</span>
       </li>
       </Link>
      </ul>
     </div>
     {/* <div className='bottom'>
      <div className="colorOption" onClick={() => dispatch({type: "LIGHT"})}></div>
      <div className="colorOption" onClick={() => dispatch({type: "DARK"})}></div>
     </div> */}

    </div>
  )
}

export default Sidebar