import {createStore} from 'redux'
import reducer from './Reducers/index'
// import {configureStore} from '@reduxjs/toolkit'
import { persistStore, persistReducer,FLUSH,REHYDRATE,PAUSE,PERSIST,PURGE,REGISTER, } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userReducer from '../Redux/Slice/userSlice'

const persistConfig ={
 key: 'main root',
 storage
}
const rootReducer =persistReducer(persistConfig, userReducer);
const persistedReducer = persistReducer(persistConfig, reducer)
const store = createStore(persistedReducer,{},window._REDUX_DEVTOOLS_EXTENSION_&& window._REDUX_DEVTOOLS_EXTENSION_());

const Persistor = persistStore(store);

export {Persistor}

export default store