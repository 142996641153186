import React from 'react'
import './PlatformHome.css';

const PlatformHome = ({id, stage, stageHead,activitiesPara, stageList1, stageList2,stageList3, stageList4,benefitList1,benefitList2,benefitList3, benefitList4, benefitList5,contentPara, contentparaList1,contentparaList2,contentparaList3,contentparaList4,contentparaList5,contentparaList6,contentList1,contentList2,contentList3,contentList4,contentList5,contentList6,contentList7,head}) => {
  return (
   <div className='stageContainer'>



   <h2 className='stageHead'>{stage} : <span className='stageHeadSpan'>{stageHead}</span></h2>
   <div className='stageFlex'>
     <div className='activitiesDiv'>
       <h2 className='activitiesHead'>ACTIVITIES</h2>
       <p className='activitiesPara'>{id}</p>
       <p className='activitiesPara'>{activitiesPara}</p>
       <ul>
         <li className='stageList'>{stageList1}</li>
         <li className='stageList'>{stageList2}</li>
         <li className='stageList'>{stageList3}</li>
         <li className='stageList'>{stageList4}</li>
       </ul>
     </div>
     <div className='benefitDiv'>
     <h2 className='benefitHead'>BENEFITS</h2>
       <ul>
         <li className='stageList'>{benefitList1}</li>
         <li className='stageList'>{benefitList2}</li>
         <li className='stageList'>{benefitList4}</li>
         <li className='stageList'>{benefitList3}</li>
         <li className='stageList'>{benefitList5}</li>
       </ul>
     </div>
   </div>


   </div>
  )
}

export default PlatformHome;