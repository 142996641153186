import React from 'react'
import '../../../../shared/CSS/UserSideBar.css'
import { Link } from 'react-router-dom'
import { FaWallet } from "react-icons/fa";
// import { FaUserNurse } from "react-icons/fa";


const UserSideBarw = () => {
  const signOut = () =>{
    localStorage.clear()
  }
  return (
    <div className='userContainer'>
     <ul className='listContainer'>
      <li className='userList'>
       <Link className='sidebar' to='/welcomebackdashboard'>
       Dashboard</Link></li>
       <hr />
      <li className='userList'>
       {/* <img src={FaWallet} alt="" /> */}
       <Link className='sidebar' to='/welcomebackbankdetails'>Bank Details</Link>
      </li>
      <hr />

      <li className='userList'><Link className='sidebar' to='/welcomebackpaystack'>Make Payment</Link></li>
      <hr />

      <li className='userList'><Link className='sidebar' to='/welcomebackupdatepassword'>Update Password</Link></li>
      <hr />

      <li className='userList'><Link className='sidebar' to='/welcomebackreferralcode'>Referral code</Link></li>
      <hr />

      <li className='userList'><Link className='sidebar' to='/Welcomeback' onClick={()=>signOut()}>Sign Out</Link></li>
      <hr />
     </ul>
  
    </div>
  )
}

export default UserSideBarw