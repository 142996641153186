import loader from '../../asset/images/loading.gif';
import './Preloader.css'
import React from 'react';

const Preloader = () => {
 return (
  <div className="pre">
    <img
      src={loader}
      alt="Loading"
      className='preloader'
      
    />
  </div>
);
}

export default Preloader;